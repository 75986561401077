
import { PropType, toRefs, computed } from 'vue'
import { DiscountScopes } from '@invivodf/discount-sdk/admin'
import { labeledScopes } from '@invivodf/common/src/contexts/discount/application/composables/labeledScopes'
import { use18n } from '@invivodf/locales/src/use18n'

export default {
  name: 'CDiscountScope',
  props: {
    scope: { type: String as PropType<DiscountScopes>, required: true },
  },
  setup(props) {
    const i18n = use18n()

    const { scope } = toRefs(props)

    return {
      currentScopeText: computed(() => {
        const scopeText = labeledScopes.find((s) => s.value === scope.value)?.text
        return scopeText ? i18n.t(scopeText).toString() : ''
      }),
    }
  },
}
