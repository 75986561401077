import { DiscountUnits } from '@invivodf/discount-sdk/admin'
import { VueI18n } from '@invivodf/locales/src/index'
import { Discount } from './Discount'
import { formattedMeasureUnits } from './formattedMeasureUnits'

export const formatDiscountUnitLabel = (discount: Discount, i18n: VueI18n) => {
  if (!discount.measureUnit) return discount.unit
  if (discount.unit === DiscountUnits.Percentage) return discount.unit
  return `${discount.unit}/${i18n.t(formattedMeasureUnits[discount.measureUnit].single).toString()}`
}
