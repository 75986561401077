import { RequiredProperty } from '@invivodf/common/src/contexts/discount/domain/discountForm/fields/validator/RequiredProperty'
import { FormErrors } from '../FormErrors'

export class NullableRequiredUses extends RequiredProperty<number | null> {
  constructor(validatedValue: number | null) {
    super(validatedValue, { errorMessage: FormErrors.NullableUsesRequired })
  }

  validate() {
    this.errors = []
    if (this.validatedValue !== null && this.validatedValue <= 0) {
      this.errors.push(FormErrors.NullableUsesNegative)
    }
  }
}
