import { provideCommonAuthService } from '@invivodf/common/src/auth/useAuth'
import { installCommonCustomValidateRules } from '@invivodf/common/src/infrastructure/rules/custom-common-rules.validate'

import uiKitPlugin from '@invivodf/ui-kit'
import BootstrapVue from 'bootstrap-vue'
import VeeValidate, { Validator } from 'vee-validate'
import { localize } from 'vee-validate-last'
import frLast from 'vee-validate-last/dist/locale/fr.json'
import fr from 'vee-validate/dist/locale/fr'
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import InstantSearch from 'vue-instantsearch'
import VueLazyLoad from 'vue-lazyload'
import { provideComposables } from '@invivodf/common/src/contexts/logistic-offer/application/composables/dependency-injection'
import { discountPermissions } from '@invivodf/common/src/contexts/discount/application/routes/discount-permissions'

import { provideAuthService, useAuthService } from '@b2ag/auth'
import { provideScopesService } from '@/auth'
import { initPlugin } from '@invivodf/locales/src'
import { getLocaleForBrand } from '@invivodf/locales/src/localeByBrand'
import App from './App.vue'
import dictionaryLast from './core/dictionaries'
import AxeptioPlugin from './plugins/axeptio.plugin'
import router from './router'
import installCustomValidateRules from './services/custom-rules.validate'
import store from './store'
import { SELLER_SCOPES } from './SELLER_SCOPES'

const dictionary = {
  fr: {
    attributes: {
      price: 'prix',
      shippingStartDate: 'date de début de livraison',
      shippingEndDate: 'date de fin de livraison',
      unitPrice: 'prix',
      measurementPrice: 'prix',
      shippingWays: 'mode de livraison',
      validityStartDate: 'date de début de validité',
      validityEndDate: 'date de fin de validité',
      validityStartDateTarget: 'date de début de validité',
      priceType: 'type de prix',
      sellerCustomId: 'ID offre',
      newQuantity: 'quantité',
      unitType: 'type d‘unité',
      publicName: 'nom',
      internalName: 'nom interne',
      productCategory: 'Catégorie',
      measureUnit: 'Unité de mesure',
    },
  },
}
for (let i = 0; i < 100; i += 1) {
  dictionary.fr.attributes[`sliceMin${i}`] = 'minimum'
  dictionary.fr.attributes[`sliceMax${i}`] = 'maximum'
  dictionary.fr.attributes[`sliceDiscount${i}`] = 'remise'
}

Vue.config.productionTip = false

Vue.use(InstantSearch)
Vue.use(VueLazyLoad, {
  preLoad: 1.3,
  attempt: 1,
  lazyComponent: true,
})

Vue.use(uiKitPlugin as any, {
  environmentName: window.env.ENVIRONMENT_NAME,
  cloudinaryBucketName: window.env.CLOUDINARY_BUCKET_NAME,
})

// Analytics
if (!window.Cypress && window.env.AXEPTIO_CLIENT_ID) {
  Vue.use(AxeptioPlugin, {
    clientId: window.env?.AXEPTIO_CLIENT_ID,
    base: window.env?.AXEPTIO_BASE,
  })
}
Vue.use(VueAnalytics, {
  id: window.env.ANALYTICS_ID,
  disabled: !Vue.$axeptioPluginInstalled,
  router,
})

Vue.use(BootstrapVue)
Validator.localize('fr', fr)
Validator.localize(dictionary)
Vue.use(VeeValidate, {
  // Important to name this something other than 'fields'
  fieldsBagName: 'veeFields',
  // This is not required but avoids possible naming conflicts
  errorBagName: 'veeErrors',
  locale: 'fr',
})
installCustomValidateRules()
installCommonCustomValidateRules()

localize('fr', frLast)
localize(dictionaryLast)

// Global include all components from the design system
// @ts-ignore pas nécessaire de gérer ce cas
const requireComponent = require.context('@b2ag/design-system/dist/components/', true, /Adn[A-Z]\w+\.vue$/)
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^.*\//, '').replace(/\.vue$/, '')
  Vue.component(componentName, componentConfig.default || componentConfig)
})

const REQUESTED_SCOPES = [...SELLER_SCOPES, ...discountPermissions]

async function initAuthService() {
  const extraConfig: any = {
    extranetConnection: window.env.AUTH0_EXTRANET_CONNECTION,
    smagConnection: window.env.AUTH0_SMAG_CONNECTION,
  }

  await provideAuthService({
    audience: window.env.AUTH0_AUDIENCE!,
    domain: window.env.AUTH0_DOMAIN!,
    redirectUri: `${window.location.origin}/oauth/callback`,
    authority: window.env.AUTH0_DOMAIN!,
    clientId: window.env.AUTH0_CLIENT_ID!,
    requiredScopes: REQUESTED_SCOPES,
    ...extraConfig,
  })

  provideCommonAuthService({
    getAccessToken: () => useAuthService().getAccessToken(),
    getUserScopes: async () => (await useAuthService().getScopes()) || [],
  })

  provideScopesService(SELLER_SCOPES)
}

async function startApp() {
  await initAuthService()
  new Vue({
    router,
    store,
    provide: { ...provideComposables() },
    i18n: initPlugin(
      getLocaleForBrand(window.env.VUE_APP_CONTEXT).locale,
      Vue,
      getLocaleForBrand(window.env.VUE_APP_CONTEXT).lang,
    ),
    render: (h) => h(App),
  }).$mount('#app')
}

startApp()
