import { DiscountTypes, DiscountScopes } from '@invivodf/discount-sdk/admin'
import { DiscountPolicy } from '../DiscountPolicy'

export interface DisplayedDiscountPolicies {
  readonly value: DiscountPolicy
  readonly text: string
  readonly description: string
  readonly example: string
  readonly dataTest: string
}

const simpleArticle = new DiscountPolicy(DiscountTypes.Simple, DiscountScopes.Article)
const simpleCart = new DiscountPolicy(DiscountTypes.Simple, DiscountScopes.Cart)
const simpleGroup = new DiscountPolicy(DiscountTypes.Simple, DiscountScopes.Group)
const revenueArticle = new DiscountPolicy(DiscountTypes.OnRevenue, DiscountScopes.Article)
const revenueCart = new DiscountPolicy(DiscountTypes.OnRevenue, DiscountScopes.Cart)
const quantitativeArticle = new DiscountPolicy(DiscountTypes.Quantitative, DiscountScopes.Article)
const fixedPriceArticle = new DiscountPolicy(DiscountTypes.FixedPrice, DiscountScopes.Article)

export const DISCOUNT_POLICIES_BY_ID: { [key: string]: DisplayedDiscountPolicies } = {
  [simpleArticle.id]: {
    value: simpleArticle,
    text: 'ctx.discount.policies.simple-article.name',
    description: 'ctx.discount.policies.simple-article.description',
    example: `ctx.discount.policies.simple-article.example`,
    dataTest: 'link-to-create-simple-article',
  },
  [simpleCart.id]: {
    value: simpleCart,
    text: 'ctx.discount.policies.simple-cart.name',
    description: 'ctx.discount.policies.simple-cart.description',
    example: `ctx.discount.policies.simple-cart.example`,
    dataTest: 'link-to-create-simple-cart',
  },
  [simpleGroup.id]: {
    value: simpleGroup,
    text: 'ctx.discount.policies.simple-group.name',
    description: `ctx.discount.policies.simple-group.description`,
    example: 'ctx.discount.policies.simple-group.example',
    dataTest: 'link-to-create-simple-group',
  },
  [revenueArticle.id]: {
    value: revenueArticle,
    text: 'ctx.discount.policies.revenue-article.name',
    description: 'ctx.discount.policies.revenue-article.description',
    example: `ctx.discount.policies.revenue-article.example`,
    dataTest: 'link-to-create-revenue-article',
  },
  [revenueCart.id]: {
    value: revenueCart,
    text: 'ctx.discount.policies.revenue-cart.name',
    description: 'ctx.discount.policies.revenue-cart.description',
    example: 'ctx.discount.policies.revenue-cart.example',
    dataTest: 'link-to-create-revenue-cart',
  },
  [quantitativeArticle.id]: {
    value: quantitativeArticle,
    text: 'ctx.discount.policies.quantitative-article.name',
    description: 'ctx.discount.policies.quantitative-article.description',
    example: 'ctx.discount.policies.quantitative-article.example',
    dataTest: 'link-to-create-quanti-article',
  },
  [fixedPriceArticle.id]: {
    value: fixedPriceArticle,
    text: 'ctx.discount.policies.fixed-price-article.name',
    description: 'ctx.discount.policies.fixed-price-article.description',
    example: 'ctx.discount.policies.fixed-price-article.example',
    dataTest: 'link-to-create-fixed-price-article',
  },
}
