/* eslint-disable camelcase, no-use-before-define */
import jwtDecode from 'jwt-decode'
import { AuthService } from '@b2ag/auth'

export interface Config {
  audience: string
  authority: string
  clientId: string
  scope: string
  extranetConnection?: string
  smagConnection?: string
}

export async function getAladinRoles(authService: AuthService): Promise<Record<string, AladinRole | null>> {
  const accessToken = await authService.getAccessToken()
  const decodedRoles = {
    [AladinRoleType.seller]: null,
    [AladinRoleType.tech]: null,
  }
  if (!accessToken) {
    return decodedRoles
  }
  const decodedToken = jwtDecode(accessToken)
  if (!decodedToken) {
    return decodedRoles
  }
  return {
    [AladinRoleType.seller]:
      decodedToken[`${window.env.AUTH0_AUDIENCE}/v1/roles`]?.filter(
        (role) => role.type === AladinRoleType.seller,
      )?.[0] ?? null,
    [AladinRoleType.tech]:
      decodedToken[`${window.env.AUTH0_AUDIENCE}/v1/roles`]?.filter((role) => role.type === AladinRoleType.tech)?.[0] ??
      null,
  }
}

/**
 * Basic contents of any Aladin access token
 */
interface BaseAccessToken {
  iss: string
  sub: string
  aud: string[]
  iat: number
  exp: number
  azp: string
  scope: string
  /**
   * @deprecated
   */
  permissions: string[]
}

/**
 * Role contents of any Aladin access token
 */
interface RoleAccessToken {
  [key: string]: AladinRole[] | undefined
}

/**
 * Available role types
 */
export enum AladinRoleType {
  farmer = 'farmer',
  seller = 'seller',
  tech = 'tech',
  supplier = 'supplier',
  machine = 'machine',
}

export interface AladinProfile {
  id?: string
  uid?: string
  membership_number?: string

  cooperative: {
    id: number
    name: string
  }
}

export interface AladinRole {
  /**
   * @deprecated
   */
  type: AladinRoleType
  permissions: string[]
  profiles: AladinProfile[]
}

export type AladinAccessToken = BaseAccessToken & RoleAccessToken
