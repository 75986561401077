import algoliasearch from 'algoliasearch/lite'
import { computed } from 'vue'
import { useStore } from '@/composables/useStore'
import { FACETS_LABELS } from '@/contexts/custom-filters/application/composable/facets.setting'

export const useAlgoliaSearch = () => {
  const store = useStore()
  const cooperativeIndex = computed(() => store.getters.cooperativeIndex)
  const algoliaAppId = window.env.ALGOLIA_APP_ID || ''
  const algoliaApiKey = window.env.ALGOLIA_API_KEY || ''
  const searchClient = algoliasearch(algoliaAppId, algoliaApiKey)
  const { indexName } = cooperativeIndex.value

  const fetchAvailableFacets = async (categoryValue: string, cropValue: string) => {
    try {
      const filters = `categoriesLevel0:'${categoryValue}' AND crop_oad:'${cropValue}'`

      const { facets } = await cooperativeIndex.value.search('', {
        filters,
        facets: '*',
        maxValuesPerFacet: 1,
        hitsPerPage: 0,
      })

      return Object.keys(facets || {})
        .filter((key) => key in FACETS_LABELS)
        .map((facet) => ({ label: FACETS_LABELS[facet], value: facet }))
    } catch (error) {
      return []
    }
  }

  return {
    indexName,
    searchClient,
    fetchAvailableFacets,
  }
}
