import { RequiredPropertyOptions } from '@invivodf/common/src/contexts/discount/domain/discountForm/fields/validator/RequiredProperty'
import { RequiredString } from '@invivodf/common/src/contexts/discount/domain/discountForm/fields/validator/RequiredString'
import { FormErrors } from '../FormErrors'

export interface RequiredAllowedStringOptions extends RequiredPropertyOptions {
  allowedErrorMessage: FormErrors
  allowed: string[]
}

export class RequiredAllowedString extends RequiredString {
  private allowed: string[]

  private readonly allowedErrorMessage: FormErrors

  constructor(validatedValue: string, { allowed, allowedErrorMessage, ...options }: RequiredAllowedStringOptions) {
    super(validatedValue, options)
    this.allowed = allowed
    this.allowedErrorMessage = allowedErrorMessage
  }

  public validate() {
    super.validate()
    if (!this.allowed.includes(this.validatedValue)) {
      this.errors.push(this.allowedErrorMessage)
    }
  }
}
