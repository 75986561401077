
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import { IdfButton, IdfSelect } from '@invivodf/ui-kit'
import { useRouter } from '@invivodf/common/src/application/composables/useRouter'
import { AisInstantSearch, AisRefinementList, AisConfigure } from 'vue-instantsearch'
import { useRoute } from 'vue-router/composables'
import { use18n } from '@invivodf/locales/src/use18n'
import { useCustomFilter } from '../../composable/useCustomFilter'
import { useAlgoliaSearch } from '../../composable/useAlgoliaSearch'

export default defineComponent({
  name: 'CCustomFiltersEditor',

  components: {
    IdfButton,
    IdfSelect,
    AisInstantSearch,
    AisRefinementList,
    AisConfigure,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()
    const i18n = use18n()
    const selectedCategory = ref<string | null>(null)
    const selectedCrop = ref<string | null>(null)
    const selectedFacets = ref<string[]>([])
    const maxFilters = 6
    const availableFacets = ref<Array<{ value: string; label: string }>>([])
    const { savePartnerCustomFilters, getOnePartnerCustomFilters } = useCustomFilter()
    const { searchClient, fetchAvailableFacets, indexName } = useAlgoliaSearch()
    // eslint-disable-next-line no-unused-vars
    const notificationHandler = inject<(message: string, type?: string) => void>('notificationHandler')

    const handleCategoryChange = (value: string, refine: any) => {
      if (selectedCategory.value) {
        refine(selectedCategory.value)
      }
      selectedCategory.value = value
      refine(value)
      selectedCrop.value = null
    }

    const handleCropChange = (value: string, refine: any) => {
      if (selectedCrop.value) {
        refine(selectedCrop.value)
      }
      selectedCrop.value = value
      refine(value)
    }

    const addFilter = () => {
      if (selectedFacets.value.length < maxFilters) {
        selectedFacets.value.push('')
      }
    }

    const deleteFilter = (index: number) => {
      selectedFacets.value.splice(index, 1)
    }

    const isAddButtonVisible = computed(() => {
      return selectedFacets.value.length < maxFilters
    })

    const isValidateButtonDisabled = computed(() => {
      return (
        !selectedCategory.value ||
        !selectedCrop.value ||
        selectedFacets.value.length === 0 ||
        selectedFacets.value.some((filter) => !filter)
      )
    })
    const goToCustomFiltersList = () => {
      router.push({ name: 'custom-filters' })
    }

    const saveCustomFilters = async () => {
      if (selectedCategory.value && selectedCrop.value && selectedFacets.value) {
        try {
          await savePartnerCustomFilters(selectedCategory.value, selectedCrop.value, selectedFacets.value)
          if (notificationHandler) {
            notificationHandler(i18n.t('ctx.custom-filters.save-success').toString())
          }
          goToCustomFiltersList()
        } catch (error) {
          if (notificationHandler) {
            notificationHandler(i18n.t('ctx.custom-filters.save-error').toString(), 'danger')
          }
        }
      }
    }

    const getAvailableFacetsExcludingSelected = (currentIndex: number) => {
      const selectedValues = selectedFacets.value.slice(0, currentIndex).filter((value) => value !== null)

      return availableFacets.value.filter((facet) => !selectedValues.includes(facet.value))
    }

    const loadInitialData = async () => {
      if (route.params.category && route.params.crop) {
        const customFilters = await getOnePartnerCustomFilters(route.params.category, route.params.crop)
        if (customFilters) {
          selectedCategory.value = customFilters.categoryLabel
          selectedCrop.value = customFilters.cropLabel

          if (customFilters.filters && customFilters.filters.length > 0) {
            availableFacets.value = await fetchAvailableFacets(customFilters.categoryLabel, customFilters.cropLabel)

            selectedFacets.value = customFilters.filters.map((filterItem) => filterItem.filter)
          }
        }
      }
    }

    onMounted(async () => {
      await loadInitialData()
    })

    watch([selectedCategory, selectedCrop], async ([newCategory, newCulture]) => {
      if (newCategory && newCulture) {
        selectedFacets.value = ['']
        availableFacets.value = await fetchAvailableFacets(newCategory, newCulture)
      }
    })

    return {
      addFilter,
      availableFacets,
      deleteFilter,
      getAvailableFacetsExcludingSelected,
      goToCustomFiltersList,
      handleCategoryChange,
      handleCropChange,
      indexName,
      isAddButtonVisible,
      isValidateButtonDisabled,
      saveCustomFilters,
      searchClient,
      selectedCategory,
      selectedCrop,
      selectedFacets,
    }
  },
})
