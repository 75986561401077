<template>
  <div>
    <c-import-base
      :importFileService="productsAdditionalDataService"
      :csvProperties="csvProperties"
      :pageTitle="$t('ctx.import.products-additional-data__page-title')"
      :uploadButtonLabel="$t('ctx.import.products-additional-data__upload-button-label')"
      :reportWarning="$t('ctx.import.products-additional-data__report-warning')"
    >
      <c-import-information
        :title="$t('ctx.import.products-additional-data__import-information-title')"
        :nbResultText="$t('ctx.import.products-additional-data__nb-result-text')"
        :exportText="$t('ctx.import.products-additional-data__export-text')"
        :importInformation="importInformation"
        :downloadCSV="downloadCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { productsAdditionalDataService } from '../../services'

const csvFileProperties = {
  name: 'products-additional-data.csv',
  full_path: '/assets/csv/products-additional-data.csv',
}

export default {
  name: 'CImportProductsAdditionalData',
  components: {
    CImportBase,
    CImportInformation,
  },
  methods: {
    downloadCSV() {
      return productsAdditionalDataService.downloadProductsAdditionalDataCSV(this.cooperativeId, this.cooperative.name)
    },
  },
  computed: {
    ...mapGetters(['cooperativeId', 'cooperative']),
  },
  data() {
    return {
      csvProperties: csvFileProperties,
      productsAdditionalDataService,
      importInformation: {
        count: null,
        lastImportAt: null,
      },
      csvColumnDetails: [],
      descriptionLines: [],
    }
  },
}
</script>
