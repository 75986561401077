import { DiscountTypes } from '@invivodf/discount-sdk/admin'

export interface DisplayedDiscountType {
  readonly value: DiscountTypes
  readonly text: string
  readonly description: string
  readonly title: string
  readonly example: string
  readonly dataTest: string
}

export const DISCOUNT_TYPES_TEXT: { [key: string]: DisplayedDiscountType } = {
  QUANTITATIVE: {
    value: DiscountTypes.Quantitative,
    text: 'ctx.discount.types.quantitative.name',
    description: 'ctx.discount.types.quantitative.description',
    title: 'ctx.discount.types.quantitative.title',
    example: 'ctx.discount.types.quantitative.example',
    dataTest: 'link-to-create-quantitative-discount',
  },
  ON_REVENUE: {
    value: DiscountTypes.OnRevenue,
    text: 'ctx.discount.types.revenue.name',
    description: 'ctx.discount.types.revenue.description',
    title: 'ctx.discount.types.revenue.title',
    example: 'ctx.discount.types.revenue.example',
    dataTest: 'link-to-create-discount-on-revenue',
  },
  SIMPLE: {
    value: DiscountTypes.Simple,
    text: 'ctx.discount.types.simple.name',
    description: 'ctx.discount.types.simple.description',
    title: 'ctx.discount.types.simple.title',
    example: 'ctx.discount.types.simple.example',
    dataTest: 'link-to-create-simple-discount',
  },
  FIXED_PRICE: {
    value: DiscountTypes.FixedPrice,
    text: 'ctx.discount.types.fixed-price.name',
    description: 'ctx.discount.types.fixed-price.description',
    title: 'ctx.discount.types.fixed-price.title',
    example: 'ctx.discount.types.fixed-price.example',
    dataTest: 'link-to-create-fixed-price-discount',
  },
} as const
