
import { defineComponent, PropType, ref, computed } from 'vue'
import { IdfButtonIcon, IdfPagination } from '@invivodf/ui-kit'
import { CustomFilter } from '@b2ag/module-product-sdk'
import CDeleteConfirmationModal from '../CDeleteConfirmationModal/CDeleteConfirmationModal.vue'
import { FACETS_LABELS } from '../../composable/facets.setting'

export default defineComponent({
  name: 'CCustomFiltersTable',

  components: {
    IdfButtonIcon,
    IdfPagination,
    CDeleteConfirmationModal,
  },

  props: {
    filters: {
      type: Array as PropType<CustomFilter[]>,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },

  emits: ['edit', 'delete'],

  setup(props, { emit }) {
    const currentPage = ref(0)
    const isModalVisible = ref(false)
    const selectedFilter = ref<CustomFilter | null>(null)

    const totalPages = computed(() => Math.ceil(props.filters.length / props.itemsPerPage))

    const paginatedFilters = computed(() => {
      const start = currentPage.value * props.itemsPerPage
      const end = start + props.itemsPerPage
      return props.filters.slice(start, end)
    })

    const getFilterLabel = (filterKey: string): string => {
      return FACETS_LABELS[filterKey] || filterKey
    }

    const handlePageChange = (page: number) => {
      currentPage.value = page - 1
    }

    const onModalClose = () => {
      isModalVisible.value = false
      selectedFilter.value = null
    }

    const openDeleteModal = (filter: CustomFilter) => {
      selectedFilter.value = filter
      isModalVisible.value = true
    }

    const onConfirmDelete = () => {
      if (selectedFilter.value) {
        emit('delete', selectedFilter.value)
      }
      onModalClose()

      ifDeleteLastElementFromPageBackToPreviousPage()
    }

    const ifDeleteLastElementFromPageBackToPreviousPage = () => {
      if (paginatedFilters.value.length === 1 && currentPage.value > 0) {
        currentPage.value--
      }
    }

    return {
      currentPage,
      totalPages,
      paginatedFilters,
      isModalVisible,
      getFilterLabel,
      onModalClose,
      onConfirmDelete,
      openDeleteModal,
      handlePageChange,
    }
  },
})
