
import { defineComponent } from 'vue'
import { IdfModal, IdfButton } from '@invivodf/ui-kit'

export default defineComponent({
  name: 'CDeleteConfirmationModal',

  components: {
    IdfModal,
    IdfButton,
  },

  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['close', 'confirm'],

  setup(props, { emit }) {
    const onClose = () => {
      emit('close')
    }

    const onConfirm = () => {
      emit('confirm')
    }

    return {
      onClose,
      onConfirm,
    }
  },
})
