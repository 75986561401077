import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import ImportService from './import.service'

export default class CustomerStoreService {
  constructor(membershipApiHost) {
    this.membershipApiHost = membershipApiHost
    this.api = createAxiosClientWithAladinBearer(`${membershipApiHost}`)
  }

  async import(partnerId, file) {
    return ImportService.import(
      `${this.membershipApiHost}/backoffice/v1/import/cooperatives/${partnerId}/customers/stores`,
      file,
    )
  }

  async downloadCustomerStore(partnerId, partnerName) {
    const response = await this.api.get(`/backoffice/v1/import/cooperatives/${partnerId}/customers/stores`, {
      headers: { accept: 'text/csv' },
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', ImportService.createFileName(partnerName, 'customers-stores'))
    document.body.appendChild(link)
    link.click()
  }
}
