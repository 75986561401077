import { ProductSDK, CustomFilterOnlyLabel } from '@b2ag/module-product-sdk'
import { CustomFilterRepository } from '../domain/partner-custom-filter.respository'

export class ProductSdkCustomFilterRepository implements CustomFilterRepository {
  constructor(private readonly productSdk: ProductSDK) {}

  async updatePartnerCustomFilter(partnerId: number, customFilters: CustomFilterOnlyLabel) {
    return this.productSdk.updatePartnerCustomFilters(partnerId, customFilters)
  }

  async getPartnerCustomFilters(partnerId: number) {
    return this.productSdk.getPartnerCustomFilters(partnerId)
  }

  async getOnePartnerCustomFilters(partnerId: number, categoryId: string, cropId: string) {
    return this.productSdk.getOnePartnerCustomFilters(partnerId, categoryId, cropId)
  }

  async deletePartnerCustomFilter(partnerId: number, cropId: string, categoryId: string) {
    return this.productSdk.deletePartnerCustomFilter(partnerId, cropId, categoryId)
  }
}
