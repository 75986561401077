import { RequiredProperty } from './validator/RequiredProperty'
import { FormErrors } from '../FormErrors'

export class NullableRequiredGroupSize extends RequiredProperty<number | null> {
  constructor(validatedValue: number | null) {
    super(validatedValue, { errorMessage: FormErrors.NullableGroupSizeRequired })
  }

  validate() {
    this.errors = []
    if (this.validatedValue !== null && this.validatedValue <= 1) {
      this.errors.push(FormErrors.NullableGroupSizeShouldBeBiggerThanOne)
    }
  }
}
