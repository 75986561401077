
import { computed } from 'vue'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { use18n } from '@invivodf/locales/src/use18n'
import { useStore } from '../../composables/useStore'
import { customerStoreService } from '../../services'

const csvFileProperties = {
  name: 'customer-store.csv',
  full_path: '/assets/csv/customer-store.csv',
}
export default {
  name: 'CImportCustomerStore',
  components: {
    CImportBase,
    CImportInformation,
  },
  setup() {
    const store = useStore()
    const i18n = use18n()

    const descriptionLines = [
      `${i18n.t('ctx.import.common__description-line-1')}`,
      `${i18n.t('ctx.import.common__description-line-2')}`,
      `${i18n.t('ctx.import.common__description-line-3')}`,
      `${i18n.t('ctx.import.common__description-line-4')}`,
    ]
    const customerStoreImportInformation = computed(() => {
      return store.getters.customerStoreImportInformation
    })
    const partnerId = computed(() => {
      return store.getters.cooperativeId
    })
    const partner = computed(() => {
      return store.getters.cooperative
    })

    const importInformation = computed(() => {
      return {
        count: customerStoreImportInformation.value.customerStoreCount,
        lastImportAt: customerStoreImportInformation.value.lastCustomerStoreImportAt,
      }
    })

    function afterCustomerStoreImport() {
      store.dispatch('setCustomerStoreImportInformation')
    }

    function downloadCSV() {
      return customerStoreService.downloadCustomerStore(partnerId.value, partner.value.name)
    }

    return {
      afterCustomerStoreImport,
      customerStoreService,
      csvProperties: csvFileProperties,
      descriptionLines,
      downloadCSV,
      customerStoreImportInformation,
      importInformation,
      partner,
      partnerId,
    }
  },
}
