
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import { pluralize } from '@invivodf/common/src/core/l10n'
import { use18n } from '@invivodf/locales/src/use18n'

export default defineComponent({
  name: 'CDiscountReferenceList',
  components: { IdfButton },
  props: {
    discountReferences: { type: Array as PropType<string[]>, required: true },
  },
  setup(props) {
    const i18n = use18n()

    const { discountReferences } = toRefs(props)

    const showFullReferenceList = ref<boolean>(false)

    const toggleReferenceList = () => {
      showFullReferenceList.value = !showFullReferenceList.value
    }

    const discountReferenceList = computed(() =>
      showFullReferenceList.value ? discountReferences.value : discountReferences.value.slice(0, 1),
    )

    const toggleButtonText = computed(() => {
      if (discountReferences.value.length < 2) return null
      if (showFullReferenceList.value === true) return i18n.t('ctx.discount.detail.reduce').toString()
      const otherReferencesCount = discountReferences.value.length - 1
      return `+${otherReferencesCount} ${pluralize(i18n.t('ctx.discount.detail.other').toString(), otherReferencesCount)}`
    })

    return {
      toggleButtonText,
      toggleReferenceList,
      discountReferenceList,
    }
  },
})
