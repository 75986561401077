import { computed } from 'vue'
import { CustomFilter } from '@b2ag/module-product-sdk'
import { useStore } from '../../../../composables/useStore'
import {
  updatePartnerCustomFiltersUsecase,
  deletePartnerCustomFiltersUseCase,
  getOnePartnerCustomFiltersUseCase,
  getPartnerCustomFilterUseCase,
} from '../../dependency-injection'

export const useCustomFilter = () => {
  const store = useStore()
  const partnerId = computed(() => store.getters.cooperativeId)

  const formatFilters = (filters: string[]): { position: number; filter: string }[] => {
    return filters.map((filter, index) => {
      return {
        position: index + 1,
        filter,
      }
    })
  }

  const savePartnerCustomFilters = async (categoryLabel: string, cropLabel: string, filters: string[]) => {
    if (categoryLabel && cropLabel) {
      const filtersFormatted = formatFilters(filters)
      const customFilters = {
        categoryLabel,
        cropLabel,
        filters: filtersFormatted,
      }
      await updatePartnerCustomFiltersUsecase.execute(partnerId.value, customFilters)
    }
  }

  const getPartnerCustomFilters = async (): Promise<CustomFilter[]> => {
    return await getPartnerCustomFilterUseCase.execute(partnerId.value)
  }

  const deletePartnerCustomFilters = async (categoryId: string, cropId: string): Promise<void> => {
    return deletePartnerCustomFiltersUseCase.execute(partnerId.value, cropId, categoryId)
  }

  const getOnePartnerCustomFilters = async (categoryId: string, cropId: string): Promise<CustomFilter | null> => {
    return getOnePartnerCustomFiltersUseCase.execute(partnerId.value, categoryId, cropId)
  }

  return {
    getPartnerCustomFilters,
    savePartnerCustomFilters,
    deletePartnerCustomFilters,
    getOnePartnerCustomFilters,
  }
}
