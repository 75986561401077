
import { computed, defineComponent, PropType, toRefs } from 'vue'
import { IdfIcon } from '@invivodf/ui-kit'
import { DiscountScopes, DiscountTypes } from '@invivodf/discount-sdk/admin'
import { use18n } from '@invivodf/locales/src/use18n'
import PAlert from '../../../../../application/presenters/PAlert.vue'
import { ReactiveSlice } from '../../composables/useDiscountScaleInput'
import { IUseDiscountMeasureUnitInput } from '../../composables/useDiscountMeasureUnitInput'
import { IUseDiscountUnitInput } from '../../composables/useDiscountUnitInput'
import { useFormErrors } from '../../composables/useFormErrors'

export default defineComponent({
  name: 'CDiscountSliceForm',
  components: { PAlert, IdfIcon },
  props: {
    index: {
      type: Number,
      required: true,
    },
    discountType: {
      type: String,
      required: true,
    },
    discountScope: {
      type: String,
      required: true,
    },
    unitInput: {
      type: Object as PropType<IUseDiscountUnitInput>,
      required: true,
    },
    measureUnitInput: {
      type: Object as PropType<IUseDiscountMeasureUnitInput | null>,
      required: true,
    },
    slice: {
      type: Object as PropType<ReactiveSlice>,
      required: true,
    },
  },
  setup(props) {
    const i18n = use18n()
    const { translateError } = useFormErrors()

    const { index, measureUnitInput, discountType, discountScope } = toRefs(props)

    const selectedMeasureUnitText = computed(() => {
      const measureUnitText = measureUnitInput.value?.measureUnits.value.find(
        (measureUnit) => measureUnit.value === measureUnitInput.value?.value.value,
      )?.text
      return measureUnitText ? i18n.t(measureUnitText).toString() : null
    })

    const measureUnitByType = computed(() => {
      return discountType.value === DiscountTypes.OnRevenue
        ? i18n.t('ctx.discount.form.slice-4').toString()
        : measureUnitInput.value?.value.value
    })

    const isDisabled = computed(() => {
      return index.value !== 0
    })

    const isScopeCart = computed(() => {
      return discountScope.value === DiscountScopes.Cart
    })

    return {
      selectedMeasureUnitText,
      measureUnitByType,
      isDisabled,
      isScopeCart,
      translateError,
    }
  },
  methods: {
    removeSliceOfScale() {
      this.$emit('remove')
    },
    enableDiscountValidation() {
      this.isDiscountValidationOn = true
    },
  },
  data: () => {
    return {
      isDiscountValidationOn: false,
    }
  },
})
