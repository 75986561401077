<template>
  <div>
    <c-import-base
      :importFileService="shippingAddressesService"
      :csvProperties="csvProperties"
      :pageTitle="$t('ctx.import.shipping-addresses__title').toString()"
      :uploadButtonLabel="$t('ctx.import.shipping-addresses__description').toString()"
      :importInformation="importInformation"
      :helpLink="'https://support.invivodigitalfactory.com/hc/fr/articles/15890397426972-Contrat-d-interface-du-flux-MEMBER-ADDRESS-BOOK-FLOW'"
      @afterLoading="afterShippingAddressesImport"
    >
      <c-import-information
        :title="$t('ctx.import.shipping-addresses__informations').toString()"
        :nbResultText="$t('ctx.import.shipping-addresses__count').toString() + ' :'"
        :exportText="$t('ctx.import.shipping-addresses__export-action').toString()"
        :importInformation="importInformation"
        :downloadCSV="downloadCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { cooperativeService, shippingAddressesService } from '../../services'

const csvFileProperties = {
  name: 'addresses.csv',
  full_path: '/assets/csv/addresses.csv',
}
export default {
  name: 'CImportShippingAddresses',
  computed: {
    ...mapGetters(['shippingAddressesImportInformation', 'cooperativeId', 'cooperative']),
    importInformation() {
      const { shippingAddressesImportInformation: { shippingAddressesCount, lastShippingAddressesImportAt } = {} } =
        this
      return { count: shippingAddressesCount, lastImportAt: lastShippingAddressesImportAt }
    },
  },
  components: {
    CImportBase,
    CImportInformation,
  },
  methods: {
    afterShippingAddressesImport() {
      this.$store.dispatch('setShippingAddressesImportInformation')
    },
    downloadCSV() {
      return cooperativeService.downloadShippingAddresses(this.cooperativeId, this.cooperative.name)
    },
  },
  data() {
    return {
      csvProperties: csvFileProperties,
      shippingAddressesService,
    }
  },
}
</script>
