import { AppContext } from '@invivodf/common/app.context'

export const STATE_INVALID = '`state` does not match.'
export const COOPERATIVE_NOT_FOUND_ERROR = 'ssoNotActivatedForThisCooperative'
export const MEMBERSHIP_NOT_FOUND_ERROR = 'unknownMembershipNumber'
export const ZERO_PROFILE_FOUND_ERROR = 'noUserFoundForThisMembershipNumber'
export const TOO_MANY_PROFILE_FOUND_ERROR = 'moreThanOneUserForThisMembershipNumber'
export const INVALID_SCOPE = 'invalidScope'
export const IS_NOT_AUTHENTICATED = 'isNotAuthenticated'
export const ACCESS_DENIED = 'access_denied'
export const NOT_ALLOWED = 'not-allowed'

export const ERRORS = [
  STATE_INVALID,
  COOPERATIVE_NOT_FOUND_ERROR,
  MEMBERSHIP_NOT_FOUND_ERROR,
  ZERO_PROFILE_FOUND_ERROR,
  TOO_MANY_PROFILE_FOUND_ERROR,
  INVALID_SCOPE,
  ACCESS_DENIED,
  NOT_ALLOWED,
]

export interface ContentOnAuthError {
  title: string
  description: string
  button: {
    title: string
    linkName: string
  }
}
