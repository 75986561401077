import { RequiredProperty } from './validator/RequiredProperty'
import { FormErrors } from '../FormErrors'

export class NotRequiredValidityEndDate extends RequiredProperty<Date | null> {
  constructor(validatedValue: Date | null) {
    super(validatedValue, { errorMessage: FormErrors.NullableValidityEndDateRequired })
  }

  validate() {
    this.errors = []
    if (this.validatedValue !== null && (!(this.validatedValue instanceof Date) || Number.isNaN(+this.validatedValue)))
      this.errors.push(FormErrors.NullableValidityEndDateInvalid)
  }
}
