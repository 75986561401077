import { FormErrors } from '../../FormErrors'
import { ValidatedProperty, ValidationError } from './ValidatorProperty'

export interface RequiredPropertyOptions {
  errorMessage: FormErrors
}

export class RequiredProperty<T> implements ValidatedProperty<T> {
  public errors: ValidationError[] = []

  protected errorMessage: FormErrors

  constructor(
    public validatedValue: T,
    { errorMessage }: RequiredPropertyOptions,
  ) {
    this.errorMessage = errorMessage
  }

  public validate(..._args) {
    this.errors = this.validatedValue != null ? [] : [this.errorMessage]
  }

  get failed(): boolean {
    return Boolean(this.errors.length)
  }
}
