
import { DateTime } from 'luxon'
import { toRefs, PropType, defineComponent, computed } from 'vue'
import { use18n } from '@invivodf/locales/src/use18n'
import { getDiscountPeriodStatus } from '../../../domain/statusByPeriod'
import { Discount } from '../../../domain/Discount'
import CPeriodStatusIcon from '../CPeriodStatusIcon.vue'
import { luxonDateDisplayFormat } from '../../luxon.constants'
import { DiscountRouteName } from '../../routes/discountRouteName'
import { useDiscountDiscountablesCounter } from '../../composables/useDiscountDiscountablesCounter'
import { DISCOUNT_POLICIES_BY_ID } from '../../../domain/constants/DISCOUNT_POLICIES_BY_ID'
import { formatDiscountUnitLabel } from '../../../domain/formatDiscountUnitLabel'

export default defineComponent({
  name: 'CDiscountListItem',
  components: { CPeriodStatusIcon },
  props: {
    discount: {
      type: Object as PropType<Discount>,
      required: true,
    },
  },
  data: () => ({
    routeName: DiscountRouteName.DETAIL,
  }),
  setup(props) {
    const i18n = use18n()
    const { discount } = toRefs(props)

    const { uncompliantDiscountableCount, activeDiscountableCount, discountablesTotalCount } =
      useDiscountDiscountablesCounter(discount)

    const formatedStartDate = computed(() =>
      DateTime.fromJSDate(discount.value.validityStartDate).toFormat(luxonDateDisplayFormat),
    )

    const formatedEndDate = computed(() => {
      if (!DateTime.fromJSDate(discount.value.validityEndDate!).isValid) return '-'
      return DateTime.fromJSDate(discount.value.validityEndDate!).toFormat(luxonDateDisplayFormat)
    })

    const discountStatus = computed(() => getDiscountPeriodStatus(discount.value))

    const formatDiscountPolicy = computed(() => {
      const policy = DISCOUNT_POLICIES_BY_ID[discount.value.policy.id]
      return i18n.t((policy && policy.text) || '').toString()
    })

    const discountUnit = computed(() => formatDiscountUnitLabel(discount.value, i18n))

    return {
      uncompliantDiscountableCount,
      activeDiscountableCount,
      discountablesTotalCount,
      formatedStartDate,
      formatedEndDate,
      discountStatus,
      formatDiscountPolicy,
      discountUnit,
    }
  },
})
