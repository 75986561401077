import { AppContext } from '@invivodf/common/app.context'

export function getLocaleForBrand(brand: AppContext) {
  switch (brand) {
    case AppContext.aladin:
      return {
        lang: 'fr',
        locale: 'fr-FR',
      }

    case AppContext.farmi:
      return {
        lang: 'fr',
        locale: 'fr-FR',
      }

    case AppContext.episens:
      return {
        lang: 'fr',
        locale: 'fr-FR',
      }

    case AppContext.umg_us:
      return {
        lang: 'en',
        locale: 'en-US',
      }
    default:
      return {
        lang: 'fr',
        locale: 'fr-FR',
      }
  }
}
