<template>
  <div>
    <c-import-base
      :importFileService="offerService"
      :allCsvProperties="allCsvProperties"
      :pageTitle="$t('ctx.import.offers__page-title')"
      :uploadButtonLabel="$t('ctx.import.offers__upload-button-label')"
      :reportWarning="$t('ctx.import.offers__report-warning')"
      :helpLink="'https://support.invivodigitalfactory.com/hc/sections/15890061133596-Gestion-des-offres-commerciales'"
    >
      <c-import-information
        :title="$t('ctx.import.offers__import-information-title')"
        :nbResultText="$t('ctx.import.offers__nb-result-text')"
        :exportText="$t('ctx.import.offers__export-text')"
        :importInformation="importInformation"
        :downloadCSV="downloadCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { offerService } from '../../services'

const allCsvProperties = [
  {
    name: 'offers.csv',
    full_path: '/assets/csv/offers.csv',
    importType: 'importFull',
    importSectionTitle: 'ctx.import.offers__import-full-section-title',
    importSectionLabel: 'ctx.import.offers__import-full-section-label',
    uploadButtonLabel: 'ctx.import.offers__import-full-upload-button-label',
  },
  {
    name: 'offers.csv',
    full_path: '/assets/csv/offers.csv',
    importType: 'importDelta',
    importSectionTitle: 'ctx.import.offers__import-delta-section-title',
    importSectionLabel: 'ctx.import.offers__import-delta-section-label',
    uploadButtonLabel: 'ctx.import.offers__import-delta-upload-button-label',
  },
  {
    name: 'offers_delete.csv',
    full_path: '/assets/csv/offers_delete.csv',
    importType: 'importDelete',
    importSectionTitle: 'ctx.import.offers__import-delete-section-title',
    importSectionLabel: 'ctx.import.offers__import-delete-section-label',
    uploadButtonLabel: 'ctx.import.offers__import-delete-upload-button-label',
  },
]

export default {
  name: 'CImportOffers',
  components: {
    CImportBase,
    CImportInformation,
  },
  data() {
    return {
      allCsvProperties,
      offerService,
      importInformation: {
        count: null,
        lastImportAt: null,
      },
    }
  },
  computed: {
    ...mapGetters(['cooperativeId', 'cooperative']),
  },
  methods: {
    downloadCSV() {
      return offerService.downloadOffers(this.cooperativeId, this.cooperative.name)
    },
  },
}
</script>
