
import { defineComponent, onBeforeMount, PropType, ref } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import { AuthService } from '@b2ag/auth'
import { ERRORS, ACCESS_DENIED, NOT_ALLOWED } from './VAuthCallback.constants'

export default defineComponent({
  components: { IdfButton },
  props: {
    errorDetails: {
      type: Object as PropType<{
        clientId?: string
        connection?: string
        lang?: string
        errorCode?: string
        tracking?: string
        errorDescription?: string
      }>,
      required: true,
    },
    auth: {
      type: Object as PropType<AuthService>,
      default: () => ({
        logout: () => {
          return Promise.reject(new Error('No auth service provided'))
        },
      }),
      required: true,
    },
  },
  setup(props) {
    const error = ref<string | null>()

    onBeforeMount(async () => {
      const { errorCode = '' } = props.errorDetails
      if (errorCode.startsWith(NOT_ALLOWED)) {
        error.value = ACCESS_DENIED
      } else if (ERRORS.includes(errorCode)) {
        error.value = errorCode
      } else {
        error.value = 'default'
      }
    })

    return {
      error,
      logout: props.auth.logout,
    }
  },
})
