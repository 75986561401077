import { useAuthService, AuthErrorKeys } from '@b2ag/auth'
import { Route } from 'vue-router'
import { isRouteAuthorized } from '@invivodf/common/src/auth/isRouteAuthorized'
import { useScopesService } from '@/auth/useScopes'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

const AUTHENTICATION_NOT_REQUIRED_ROUTES = ['oauth-callback', 'not-found']

export default async function routerBeforeEach(to: Route, from: Route, next) {
  if (AUTHENTICATION_NOT_REQUIRED_ROUTES.includes(to.name!)) return next()
  if (to.name === 'auth-callback-error') return next()
  const authService = useAuthService()
  let isAuthenticated = await authService.isAuthenticated()
  if (!isAuthenticated) {
    try {
      await authService.checkUserAuthSession()

      isAuthenticated = await authService.isAuthenticated()
    } catch (error: any) {
      if (error.message === AuthErrorKeys.IS_NOT_AUTHENTICATED) {
        await authService.login()
        return false
      }
      return next({ name: 'auth-callback-error', query: { error_code: error.message } })
    }
  } else {
    const scopesService = useScopesService()
    const userScopes = (await authService.getScopes()) ?? []
    try {
      scopesService.validateScopes(userScopes)
    } catch (err) {
      await authService.logout()
      return false
    }

    const isAuthorized = await isRouteAuthorized(to)

    if (!isAuthorized) return false

    // eslint-disable-next-line import/no-named-as-default-member, prefer-destructuring
    const isUserLoaded = store.getters.isUserLoaded

    if (isAuthenticated && !isUserLoaded) {
      await store.dispatch('fetchUser')
    }
    return next()
  }
  return false
}
