import { formatMeasureUnit } from '@invivodf/common/src/core/l10n'
import { use18n } from '@invivodf/locales/src/use18n'
import { ShippingWay } from '@/core/offer'
import QuotaDTO from '@/contexts/quota-dashboard/infrastructure/quotaDTO'
import { OfferDTO } from '@/contexts/quota-dashboard/infrastructure/offerDTO'
import { getProductDefaultImageFull, presentShippingMethods } from '@/core'
import { FlatVariantForQuota } from '@/core/mapper/quotaConfigMapper'
import { QuotaDetail } from '@/contexts/quota-dashboard/domain/quota-detail'

interface QuotaRaw {
  reference?: string
  meshId: string
  remaining: number
  initial: number
  productId?: string
  techId?: string
  productKind?: string
  productType?: string
  thumbnail?: string
  isSoldAsUnit?: boolean
  offerSalesUnit?: string
  variantName?: string
  variantId?: string
  measureUnit?: string
  quotaDetails?: {
    reference: string
    remaining: number
    initial: number
    techId: string
  }[]
  offerId?: string
  offerValidityEndDate?: Date
  offerShippingStartDate?: Date
  offerShippingEndDate?: Date
  offerShippingMethods: ShippingWay[]
  offerSegments?: string[]
}

export default class QuotaDashboard {
  private readonly reference?: string

  readonly meshId: string

  readonly remaining: number

  readonly initial: number

  private readonly productId?: string

  private readonly techId?: string

  private readonly productKind?: string

  private readonly productType?: string

  readonly thumbnail?: string

  private readonly isSoldAsUnit?: boolean

  private readonly offerSalesUnit?: string

  readonly variantName?: string

  private readonly variantId?: string

  private readonly measureUnit?: string

  private readonly quotaDetails?: QuotaDetail[]

  readonly offerValidityEndDate?: Date

  readonly offerShippingStartDate?: Date

  readonly offerShippingEndDate?: Date

  private readonly offerShippingMethods: ShippingWay[]

  readonly offerSegments?: string[]

  private readonly offerId?: string

  constructor(quotaRaw: QuotaRaw) {
    this.reference = quotaRaw.reference
    this.meshId = quotaRaw.meshId
    this.remaining = quotaRaw.remaining
    this.initial = quotaRaw.initial
    this.productId = quotaRaw.productId
    this.techId = quotaRaw.techId
    this.productKind = quotaRaw.productKind
    this.productType = quotaRaw.productType
    this.thumbnail = quotaRaw.thumbnail
    this.isSoldAsUnit = quotaRaw.isSoldAsUnit
    this.offerSalesUnit = quotaRaw.offerSalesUnit
    this.variantName = quotaRaw.variantName
    this.variantId = quotaRaw.variantId
    this.measureUnit = quotaRaw.measureUnit
    this.quotaDetails = quotaRaw.quotaDetails?.map((quotaDetail) => new QuotaDetail(quotaDetail))
    this.offerId = quotaRaw.offerId
    this.offerValidityEndDate = quotaRaw.offerValidityEndDate
    this.offerShippingStartDate = quotaRaw.offerShippingStartDate
    this.offerShippingEndDate = quotaRaw.offerShippingEndDate
    this.offerShippingMethods = quotaRaw.offerShippingMethods
    this.offerSegments = quotaRaw.offerSegments
  }

  get shippingIcons() {
    return presentShippingMethods(this.offerShippingMethods)?.map((shippingPresenter) => shippingPresenter.icon)
  }

  get productLink() {
    return {
      name: 'variant',
      params: { variantInvivoId: this.variantId, productId: this.productId },
    }
  }

  get isQuotaGlobal() {
    return !this.quotaDetails && !this.techId
  }

  get unit() {
    if (this.offerSalesUnit) {
      return this.offerSalesUnit === 'piece' ? 'unit' : this.measureUnit
    }
    return this.isSoldAsUnit ? 'unit' : this.measureUnit
  }

  get label() {
    const i18n = use18n()

    if (this.isQuotaGlobal) {
      return i18n.t('ctx.quotas.dashbord.global')
    }
    return this.quotaDetails?.length
      ? i18n.t('ctx.quotas.dashbord.tech')
      : i18n.t('ctx.quotas.dashbord.techWithId', { techId: this.techId })
  }

  get meterText() {
    return formatMeasureUnit(this.remaining, this.unit)
  }

  get initialQuotaText() {
    return formatMeasureUnit(this.initial, this.unit)
  }

  get details() {
    return this.quotaDetails?.map((quotaDetail) => quotaDetail.toJSON())
  }

  get isValid() {
    return this.isPricer || (!!this.variantId && !!this.offerId)
  }

  get isPricer() {
    return this.meshId.includes('~')
  }

  get errorMessage() {
    return this.isValid ? '' : `Le quota ${this.reference} est configuré sur une offre invalide`
  }

  static buildFrom(
    quotasWithSameMeshId: QuotaDTO[],
    variantFlat?: FlatVariantForQuota,
    offer?: OfferDTO,
  ): QuotaDashboard {
    return new QuotaDashboard({
      reference: quotasWithSameMeshId.length === 1 ? quotasWithSameMeshId[0].reference : undefined,
      meshId: quotasWithSameMeshId[0].meshId,
      remaining: quotasWithSameMeshId.reduce((sum, quota) => sum + quota.remaining, 0),
      initial: quotasWithSameMeshId.reduce((sum, quota) => sum + quota.initial, 0),
      // eslint-disable-next-line no-underscore-dangle
      productId: variantFlat?.productId,
      techId: quotasWithSameMeshId.length === 1 ? quotasWithSameMeshId[0].techId : undefined,
      productKind: variantFlat?.productKind,
      productType: variantFlat?.productType,
      thumbnail: variantFlat
        ? variantFlat.image || getProductDefaultImageFull(variantFlat.productKind, variantFlat.productType)
        : undefined,
      isSoldAsUnit: variantFlat?.isSoldAsUnit,
      offerSalesUnit: offer?.salesUnit,
      variantName: variantFlat?.name,
      variantId: variantFlat?.id,
      measureUnit: variantFlat?.measure_unit,
      offerId: offer?.offerId,
      offerValidityEndDate: offer?.validityEndDate,
      offerShippingStartDate: offer?.shippingStartDate,
      offerShippingEndDate: offer?.shippingEndDate,
      offerShippingMethods: offer?.shippingMethodList ?? [],
      offerSegments: offer?.segmentList,
      quotaDetails:
        quotasWithSameMeshId.length > 1
          ? quotasWithSameMeshId.map((quotaRaw) => ({
              reference: quotaRaw.reference,
              remaining: quotaRaw.remaining,
              initial: quotaRaw.initial,
              techId: quotaRaw.techId ?? '',
            }))
          : undefined,
    })
  }
}
