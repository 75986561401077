import { RequiredProperty } from '@invivodf/common/src/contexts/discount/domain/discountForm/fields/validator/RequiredProperty'
import { FormErrors } from '../FormErrors'

export class NotRequiredSegment extends RequiredProperty<string | null> {
  constructor(validatedValue: string | null) {
    super(validatedValue, { errorMessage: FormErrors.NullableSegmentRequiredError })
  }

  validate() {
    this.errors = []
    if (NotRequiredSegment.hasValue(this.validatedValue)) {
      if (this.validatedValue.length > 128) {
        this.errors.push(FormErrors.NullableSegmentTooLong)
      }
      if (!/^[a-z0-9 -_]+$/g.test(this.validatedValue)) {
        this.errors.push(FormErrors.NullableSegmentInvalidCharacters)
      }
    }
  }

  private static hasValue(value): value is string {
    return value !== null && value?.length !== 0
  }
}
