<template>
  <c-import-base
    :csvProperties="csvProperties"
    :importFileService="quotaService"
    :pageTitle="`${$t('ctx.import.quotas__title')}`"
    :uploadButtonLabel="`${$t('ctx.import.quotas__description')}`"
    :reportWarning="`${$t('ctx.import.quotas__report-warning')}`"
    :helpLink="'https://support.invivodigitalfactory.com/hc/sections/15890061133596-Gestion-des-offres-commerciales'"
  >
    <c-import-information
      :title="`${$t('ctx.import.quotas__informations')}`"
      :nbResultText="`${$t('ctx.import.quotas__count')}`"
      :exportText="`${$t('ctx.import.quotas__export-action')}`"
      :importInformation="importInformation"
      :downloadCSV="downloadCSV"
    />
  </c-import-base>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { quotaService } from '../../services'

const csvFileProperties = {
  name: 'quotas.csv',
  full_path: '/assets/csv/quotas.csv',
}
export default {
  name: 'CImportQuotas',
  components: {
    CImportBase,
    CImportInformation,
  },
  data() {
    return {
      csvProperties: csvFileProperties,
      quotaService,
      importInformation: {
        count: null,
        lastImportAt: null,
      },
    }
  },
  computed: {
    ...mapGetters(['cooperativeId', 'cooperative']),
  },
  methods: {
    downloadCSV() {
      return quotaService.downloadQuotas(this.cooperativeId, this.cooperative.name)
    },
  },
}
</script>
