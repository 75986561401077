
import { computed, defineComponent, PropType, toRefs } from 'vue'
import { use18n } from '@invivodf/locales/src/use18n'
import CFileDownload from './CFileDownload.vue'

export default defineComponent({
  name: 'CAssociatedParticipants',
  components: { CFileDownload },
  props: {
    participants: {
      type: Array as PropType<number[]>,
      required: true,
    },
  },
  setup(props) {
    const i18n = use18n()

    const { participants } = toRefs(props)

    return {
      fileContent: computed(() => `seller_id\r\n${participants.value.join('\r\n')}`),
      placeholder: computed(
        () => `${participants.value.length} ${i18n.t('ctx.discount.detail.associated-structures')}`,
      ),
    }
  },
})
