import VDiscountList from '../../views/VDiscountList.vue'
import VLayoutDiscount from '../../views/VLayoutDiscount.vue'
import VDiscount from '../../views/VDiscount.vue'
import VCreateDiscount from '../../views/VCreateDiscount.vue'
import VDuplicateDiscount from '../../views/VDuplicateDiscount.vue'
import VUpdateDiscount from '../../views/VUpdateDiscount.vue'
import VImportDiscountAssociations from '../../views/VImportDiscountAssociations.vue'
import { DiscountRouteName } from './discountRouteName'
import { DiscountPermissions } from './discount-permissions'
import { GuardAuthType } from '../../../../auth/isAuthorized'
import { DISCOUNT_POLICIES_BY_ID } from '../../domain/constants/DISCOUNT_POLICIES_BY_ID'

const existingDiscountPolicies = Object.keys(DISCOUNT_POLICIES_BY_ID).join('|')

export const discountRoutes = [
  {
    path: 'remises',
    component: VLayoutDiscount,
    children: [
      {
        path: '/',
        name: DiscountRouteName.LISTING,
        component: VDiscountList,
        meta: {
          auth: {
            permissions: [DiscountPermissions.Vendor, DiscountPermissions.Seller, DiscountPermissions.All],
            type: GuardAuthType.someOf,
          },
        },
      },
      {
        path: ':discountId',
        name: DiscountRouteName.DETAIL,
        component: VDiscount,
        props: true,
        meta: {
          auth: {
            permissions: [DiscountPermissions.Vendor, DiscountPermissions.Seller, DiscountPermissions.All],
            type: GuardAuthType.someOf,
          },
        },
      },
      {
        path: `creer/:discountPolicyId(${existingDiscountPolicies})`,
        name: DiscountRouteName.CREATE,
        component: VCreateDiscount,
        props: true,
        meta: {
          auth: {
            permissions: [DiscountPermissions.Vendor, DiscountPermissions.Seller, DiscountPermissions.All],
            type: GuardAuthType.someOf,
          },
        },
      },
      {
        path: `:discountPolicyId(${existingDiscountPolicies})/:discountId/modifier`,
        name: DiscountRouteName.UPDATE,
        component: VUpdateDiscount,
        props: true,
        meta: {
          auth: {
            permissions: [DiscountPermissions.Vendor, DiscountPermissions.Seller, DiscountPermissions.All],
            type: GuardAuthType.someOf,
          },
        },
      },
      {
        path: `:discountPolicyId(${existingDiscountPolicies})/:discountId/dupliquer`,
        name: DiscountRouteName.DUPLICATE,
        component: VDuplicateDiscount,
        props: true,
        meta: {
          auth: {
            permissions: [DiscountPermissions.Vendor, DiscountPermissions.Seller, DiscountPermissions.All],
            type: GuardAuthType.someOf,
          },
        },
      },
    ],
  },
]

export const discountAssociationParametersRoutes = [
  {
    path: 'import-associations-remises',
    name: DiscountRouteName.IMPORT_ASSOCIATION,
    component: VImportDiscountAssociations,
    meta: {
      auth: {
        permissions: [DiscountPermissions.Vendor, DiscountPermissions.Seller, DiscountPermissions.All],
        type: GuardAuthType.someOf,
      },
    },
  },
]
