import { use18n } from '@invivodf/locales/src/use18n'
import { FormErrors } from '../../domain/discountForm/FormErrors'

export const useFormErrors = () => {
  const i18n = use18n()

  const translateError = (error: FormErrors): string => {
    const i18nKey = errors[error]
    return i18nKey ? i18n.t(`ctx.discount.form.errors.${i18nKey}`).toString() : ''
  }

  return { translateError }
}

const errors: Partial<Record<FormErrors, string>> = {
  [FormErrors.ReferenceRequired]: 'reference-required',
  [FormErrors.InternalNameRequired]: 'internal-name-required',
  [FormErrors.PublicNameRequired]: 'public-name-required',
  [FormErrors.UnitRequired]: 'unit-required',
  [FormErrors.UnitInvalid]: 'unit-invalid',
  [FormErrors.MeasureUnitRequired]: 'measure-unit-required',
  [FormErrors.MeasureUnitInvalid]: 'measure-unit-invalid',
  [FormErrors.IsFlashRequired]: 'is-flash-required',
  [FormErrors.IsSalesRequired]: 'is-sales-required',
  [FormErrors.NullableSegmentTooLong]: 'nullable-segment-too-long',
  [FormErrors.NullableSegmentInvalidCharacters]: 'nullable-segment-invalid-characters',
  [FormErrors.NullableValidityEndDateInvalid]: 'nullable-validity-end-date-invalid',
  [FormErrors.ValidityStartDateRequired]: 'validity-start-date-required',
  [FormErrors.ValidityStartDateInvalid]: 'validity-start-date-invalid',
  [FormErrors.NullablePromoCodeTooLong]: 'nullable-promo-code-too-long',
  [FormErrors.NullablePromoCodeInvalidCharacters]: 'nullable-promo-code-invalid-characters',
  [FormErrors.NullableUsesNegative]: 'nullable-uses-negative',
  [FormErrors.NullableGroupSizeShouldBeBiggerThanOne]: 'nullable-group-size-should-be-bigger-than-one',
  [FormErrors.AmountInvalid]: 'amount-invalid',
  [FormErrors.AmountAbove100Percent]: 'amount-above100-percent',
  [FormErrors.AmountNegative]: 'amount-negative',
  [FormErrors.SliceMinRequired]: 'slice-min-required',
  [FormErrors.SliceMinNegative]: 'slice-min-negative',
  [FormErrors.SliceMinNotEqualToPreviousMax]: 'slice-min-not-equal-to-previous-max',
  [FormErrors.SliceMaxRequired]: 'slice-max-required',
  [FormErrors.SliceMaxInvalid]: 'slice-max-invalid',
  [FormErrors.SliceMaxNotGreaterThanMin]: 'slice-max-not-greater-than-min',
  [FormErrors.SliceAmountRequired]: 'slice-amount-required',
  [FormErrors.SliceAmountNegative]: 'slice-amount-negative',
  [FormErrors.SliceAmountAbove100Percent]: 'slice-amount-above100-percent',
}
