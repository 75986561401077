export enum FormErrors {
  ReferenceRequired = 'ReferenceRequired',
  NullableSegmentRequiredError = 'NullableSegmentRequiredError',
  NullableSegmentTooLong = 'NullableSegmentTooLong',
  NullableSegmentInvalidCharacters = 'NullableSegmentInvalidCharacters',
  InternalNameRequired = 'InternalNameRequired',
  PublicNameRequired = 'PublicNameRequired',
  UnitRequired = 'UnitRequired',
  UnitInvalid = 'UnitInvalid',
  MeasureUnitRequired = 'MeasureUnitRequired',
  MeasureUnitInvalid = 'MeasureUnitInvalid',
  IsFlashRequired = 'IsFlashRequired',
  IsSalesRequired = 'IsSalesRequired',
  NullableUsesRequired = 'NullableUsesRequired',
  NullableUsesNegative = 'NullableUsesNegative',
  NullableGroupSizeRequired = 'NullableGroupSizeRequired',
  NullableGroupSizeShouldBeBiggerThanOne = 'NullableGroupSizeShouldBeBiggerThanOne',
  AmountRequired = 'AmountRequired',
  AmountInvalid = 'AmountInvalid',
  AmountAbove100Percent = 'AmountAbove100Percent',
  AmountNegative = 'AmountNegative',
  SliceMinRequired = 'SliceMinRequired',
  SliceMinNegative = 'SliceMinNegative',
  SliceMinNotEqualToPreviousMax = 'SliceMinNotEqualToPreviousMax',
  SliceMaxRequired = 'SliceMaxRequired',
  SliceMaxInvalid = 'SliceMaxInvalid',
  SliceMaxNotGreaterThanMin = 'SliceMaxNotGreaterThanMin',
  SliceAmountRequired = 'SliceAmountRequired',
  SliceAmountNegative = 'SliceAmountNegative',
  SliceAmountAbove100Percent = 'SliceAmountAbove100Percent',
  NullableValidityEndDateRequired = 'NullableValidityEndDateRequired',
  NullableValidityEndDateInvalid = 'NullableValidityEndDateInvalid',
  ValidityStartDateRequired = 'ValidityStartDateRequired',
  ValidityStartDateInvalid = 'ValidityStartDateInvalid',
  NullablePromoCodeRequired = 'NullablePromoCodeRequired',
  NullablePromoCodeTooLong = 'NullablePromoCodeTooLong',
  NullablePromoCodeInvalidCharacters = 'NullablePromoCodeInvalidCharacters',
}
