import { CustomFilterOnlyLabel } from '@b2ag/module-product-sdk'
import { CustomFilterRepository } from '../../domain/partner-custom-filter.respository'

export class UpdatePartnerCustomFiltersUsecase {
  constructor(private readonly highlightedFilterRepository: CustomFilterRepository) {}

  execute(partnerId: number, customFilters: CustomFilterOnlyLabel) {
    return this.highlightedFilterRepository.updatePartnerCustomFilter(partnerId, customFilters)
  }
}
