<template>
  <div class="home">
    <div class="container">
      <h1 class="home__heading">
        {{ welcomeMessage }}
        <p-icon class="home__heading-logo" width="50" height="50" :kind="currentIcon" />
      </h1>
    </div>
    <div class="container-grey">
      <div class="container">
        <div class="home__block">
          <h2 class="home__block-title">{{ $t('ctx.home.customers__import-title') }}</h2>
          <p v-html="$t('ctx.home.customers__import-description')"></p>
          <div class="home__block-buttons">
            <IdfButton kind="link" tag="router-link" :route="{ name: 'import-members' }" icon-left="upload">{{
              $t('ctx.home.customers__import-action')
            }}</IdfButton>
          </div>
        </div>

        <div class="home__block">
          <h2 class="home__block-title">{{ $t('ctx.home.shipping-addresses__import-title') }}</h2>
          <p v-html="$t('ctx.home.shipping-addresses__import-description')"></p>
          <div class="home__block-buttons">
            <IdfButton
              kind="link"
              tag="router-link"
              :route="{ name: 'import-shipping-addresses' }"
              icon-left="upload"
              >{{ $t('ctx.home.shipping-addresses__import-action') }}</IdfButton
            >
          </div>
        </div>

        <div class="home__block">
          <h2 class="home__block-title">{{ $t('ctx.home.enrich-catalog.title') }}</h2>
          <p v-html="$t('ctx.home.enrich-catalog.description', { members: $t('message.memberships') })"></p>
          <p></p>
          <div class="home__block-buttons">
            <IdfButton kind="link" tag="router-link" :route="{ name: 'import-variants' }" icon-left="upload">
              {{ $t('ctx.home.enrich-catalog.import-articles-action') }}
            </IdfButton>
            <IdfButton kind="link" tag="router-link" :route="{ name: 'search-national' }" icon-left="book-outlined">
              {{ $t('ctx.home.enrich-catalog.consult-action') }}
            </IdfButton>
          </div>
        </div>

        <div class="home__block">
          <h2 class="home__block-title">{{ $t('ctx.home.create-offers.title') }}</h2>
          <p
            v-html="
              $t('ctx.home.create-offers.description', {
                partner: $t('message.partner'),
                members: $t('message.memberships'),
              })
            "
          ></p>
          <div class="home__block-buttons">
            <IdfButton kind="link" tag="router-link" :route="{ name: 'search-cooperative' }" icon-left="store-outlined">
              {{ $t('ctx.home.create-offers.consult-catalog-action', { partner: $t('message.partner') }) }}
            </IdfButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IdfButton } from '@invivodf/ui-kit'
import { computed, defineComponent } from 'vue'
import PIcon from '@invivodf/common/src/application/presenters/PIcon/PIcon.vue'
import { use18n } from '@invivodf/locales/src/use18n'

export default defineComponent({
  name: 'VHome',
  components: {
    PIcon,
    IdfButton,
  },
  setup() {
    const i18n = use18n()
    const environment = window.env.ENVIRONMENT_NAME || ''
    const environmentInformation = ['DEV', 'QUA', 'TEST'].includes(environment)
      ? i18n.t('message.homepage.environment', { environmentName: environment })
      : ''
    const welcomeMessage = `${i18n.t('message.homepage.welcome')} ${environmentInformation}`

    const currentIcon = computed(() => {
      const logo = 'logo-aladin'
      if (['DEV', 'QUA', 'TEST'].includes(environment)) {
        return `${logo}-${environment.toLowerCase()}`
      }
      return logo
    })

    return {
      welcomeMessage,
      currentIcon,
    }
  },
})
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
.home__heading {
  text-align: center;
  font-weight: bold;
  margin: 2em 0;
}
.home__heading-logo.ds-bo-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-top: -5px;
}
.home__block {
  padding: 30px;
  border: 1px solid var(--idf-color-neutral-200);
  margin-bottom: 20px;
  background-color: white;
}
.home__block-disabled {
  opacity: 0.6;
  background-color: var(--idf-color-neutral-200);
}
.home__block-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1em;
}
.home__block-buttons {
  padding: var(--idf-spacing-4) 0 0;
  display: flex;
  justify-content: center;
  gap: var(--idf-spacing-4);
}
</style>
