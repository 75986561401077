
import { defineComponent, ref } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import { useMetaDiscountSellers } from '../../../composables/meta-discount/useMetaDiscountSellers'
import CAssociatedParticipants from '../../CAssociatedParticipants.vue'
import CDiscountModal, { ModalCommands } from '../../CDiscountModal.vue'
import CDiscountSellersModal from './CDiscountSellersModal.vue'
import CStepSubGroup from '../../CStepSubGroup.vue'

export default defineComponent({
  name: 'CDiscountSellersUpload',
  components: {
    IdfButton,
    CAssociatedParticipants,
    CStepSubGroup,
    CDiscountModal,
    CDiscountSellersModal,
  },
  setup() {
    const { sellers, hasSellers, setSellers } = useMetaDiscountSellers()

    const sellersUploadModal = ref<ModalCommands>()
    const showModal = () => sellersUploadModal.value!.show()
    const hideModal = () => sellersUploadModal.value!.hide()

    const onValidate = (newSellers: number[]) => {
      setSellers(newSellers)
      hideModal()
    }

    return { sellers, hasSellers, sellersUploadModal, showModal, hideModal, onValidate }
  },
})
