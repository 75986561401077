import { computed, ref } from 'vue'
import { FeatureType, useFeatureFlip } from '@invivodf/common/src/application/composables/useFeatureFlip'
import { useDiscountAssociationParameters } from '@invivodf/common/src/contexts/discount'
import { SectionName, useParametersFormatter, useTransversalParameters } from '@invivodf/common/src/contexts/parameters'
import { EnvFeaturesEnum } from '@invivodf/common/EnvFeaturesEnum'
import { AppContext } from '@invivodf/common/app.context'
import { capitalize } from 'lodash'
import { use18n } from '@invivodf/locales/src/use18n'
import { useStore } from '@/composables/useStore'

/**
 * Cette fonction devrait importer les différents paramètres de chaque contextes.
 * Chaque paramètre devrait donc être définit dans des fonctions de compositions exportées par les contextes fonctionnels correspondants.
 * Ce travail n'a pas encore était fait car beaucoup des contexts auxquels appartiennent ces paramètres n'existent pas.
 */
export const useParameters = () => {
  const i18n = use18n()
  const store = useStore()
  const { cooperativeId } = store.getters

  const importVariant = computed(() => ({
    title: `${i18n.t('ctx.parameters.product__import-variants__title')}`,
    description: `${i18n.t('ctx.parameters.product__import-variants__description')}`,
    route: 'import-variants',
    icon: 'book-outlined',
    section: SectionName.Product,
  }))

  const importOffers = computed(() => ({
    title: `${i18n.t('ctx.parameters.product__import-offers__title')}`,
    description: `${i18n.t('ctx.parameters.product__import-offers__description')}`,
    route: 'import-offers',
    icon: 'offer-outlined',
    section: SectionName.Product,
  }))

  const isImportEnabled = useFeatureFlip({
    id: 'front-seller:import-product-additional-data',
    type: FeatureType.Dynamic,
  })

  const importProductAdditionalData = computed(() => ({
    title: `${i18n.t('ctx.parameters.product__import-products-additional-data__title')}`,
    description: `${i18n.t('ctx.parameters.product__import-products-additional-data__description')}`,
    route: 'import-products-additional-data',
    icon: 'star-filled',
    section: SectionName.Product,
    enableFeature: isImportEnabled.value,
  }))

  const usersParameters = [
    computed(() => ({
      title: capitalize(i18n.t('message.memberships').toString()),
      description: `${i18n.t('ctx.parameters.users__import-users__description-1')} ${i18n.t('message.memberships')} ${i18n.t('ctx.parameters.users__import-users__description-2')} ${i18n.t(
        'message.yourPartner',
      )} ${i18n.t('ctx.parameters.users__import-users__description-3')}`,
      route: 'import-members',
      icon: 'b2b-outlined',
      section: SectionName.Users,
    })),
    computed(() => ({
      title: i18n.t('message.technicians').toString(),
      description: `${i18n.t('ctx.parameters.users__import-users__description-1')} ${i18n.t('message.technicians')} ${i18n.t('ctx.parameters.users__import-users__description-2')} ${i18n.t('message.yourPartner')}`,
      route: 'import-technical-sales',
      icon: 'b2c-outlined',
      section: SectionName.Users,
    })),
    computed(() => ({
      title: `${i18n.t('ctx.parameters.users__delivery-addresses__title')}`,
      description: `${i18n.t('ctx.parameters.users__delivery-addresses__description')} ${i18n.t('message.memberships')}`,
      route: 'import-shipping-addresses',
      icon: 'location-outlined',
      section: SectionName.Users,
    })),
    computed(() => ({
      title: `${i18n.t('ctx.parameters.users__segmentation__title')} ${i18n.t('message.memberships')}`,
      description: `${i18n.t('ctx.parameters.users__segmentation__description-1')} ${i18n.t('message.memberships')} ${i18n.t('ctx.parameters.users__segmentation__description-2')}`,
      route: 'import-segments',
      icon: 'people-outlined',
      section: SectionName.Users,
    })),
    computed(() => ({
      title: capitalize(i18n.t('message.partner-contact.import-title').toString()),
      description: `${i18n.t('ctx.parameters.users__import-users__description-1')} ${i18n.t('message.partner-contact.contacts')} ${i18n.t('ctx.parameters.users__import-users__description-2')} ${i18n.t(
        'message.yourPartner',
      )}`,
      route: 'import-contacts',
      icon: 'b2b-outlined',
      section: SectionName.Users,
    })),
    computed(() => ({
      title: capitalize(i18n.t('customer-store.import-title').toString()),
      description: `${i18n.t('customer-store.description')}`,
      route: 'import-store-customer',
      icon: 'store-outlined',
      section: SectionName.Users,
    })),
  ]

  const paymentParameters = [
    computed(() => ({
      title: `${i18n.t('ctx.parameters.payment__title')}`,
      description: `${i18n.t('ctx.parameters.payment__description')} ${i18n.t('message.frontAgri')}`,
      route: 'details-paiements-cb',
      icon: 'card-outlined',
      section: SectionName.Payment,
    })),
  ]

  const deliveryParameters = [
    computed(() => ({
      title: `${i18n.t('ctx.parameters.delivery__storage-sites__title')}`,
      description: `${i18n.t('ctx.parameters.delivery__storage-sites__description')}`,
      route: 'import-stores',
      icon: 'store-outlined',
      section: SectionName.Delivery,
    })),
    computed(() => ({
      title: `${i18n.t('ctx.parameters.delivery__delivery-zones__title')}`,
      description: `${i18n.t('ctx.parameters.delivery__delivery-zones__description')}`,
      route: 'zone-charges',
      icon: 'truck-outlined',
      section: SectionName.Delivery,
    })),
  ]

  const isCustomFiltersActive = useFeatureFlip({
    id: 'custom-filters-configuration',
    type: FeatureType.Dynamic,
    context: ref({
      id: cooperativeId.toString(),
    }),
  })

  const marketingParameters = [
    computed(() => ({
      title: `${i18n.t('ctx.parameters.marketing__articles-news__title')}`,
      route: 'articles',
      icon: 'file-outlined',
      section: SectionName.Marketing,
    })),
    computed(() => ({
      title: `${i18n.t('ctx.parameters.marketing__home-banner__title')}`,
      route: 'partner-banner',
      icon: 'profile-card-outlined',
      section: SectionName.Marketing,
    })),
    computed(() => ({
      title: `${i18n.t('ctx.parameters.product__custom-filters__title')}`,
      route: 'custom-filters',
      icon: 'filter-outlined',
      enableFeature: isCustomFiltersActive.value,
      section: SectionName.Marketing,
    })),
  ]

  const isImportPaymentDueDatesActive = useFeatureFlip({
    id: EnvFeaturesEnum.PAYMENT_DUE_DATES_FEATURE,
    type: FeatureType.Env,
  })

  const importPaymentDueDates = computed(() => ({
    title: `${i18n.t('ctx.parameters.product__import-payment-due-dates__title')}`,
    description: `${i18n.t('ctx.parameters.product__import-payment-due-dates__description')}`,
    route: 'payment-due-dates',
    icon: 'offer-outlined',
    enableFeature: isImportPaymentDueDatesActive.value,
    section: SectionName.Product,
  }))

  const productParameters = [importVariant, importPaymentDueDates, importProductAdditionalData]

  const offerParameters = [importOffers]

  const stockParameters = [
    computed(() => ({
      title: `${i18n.t('ctx.parameters.stock-and-quota__import-stock__title')}`,
      description: `${i18n.t('ctx.parameters.stock-and-quota__import-stock__description')}`,
      route: 'import-stock',
      icon: 'upload',
      section: SectionName.StockAndQuota,
    })),
    computed(() => ({
      title: `${i18n.t('ctx.parameters.stock-and-quota__stock-threshold__title')}`,
      description: `${i18n.t('ctx.parameters.stock-and-quota__stock-threshold__description')}`,
      route: 'stock-thresholds',
      icon: 'upload',
      enableFeature: false,
      section: SectionName.StockAndQuota,
    })),
  ]

  const isImportQuotaActive = useFeatureFlip({
    id: EnvFeaturesEnum.IMPORT_QUOTA_FEATURE,
    type: FeatureType.Env,
  })
  const quotaParameters = [
    computed(() => ({
      title: `${i18n.t('ctx.parameters.stock-and-quota__import-quota__title')}`,
      description: `${i18n.t('ctx.parameters.stock-and-quota__import-quota__description')}`,
      route: 'import-quotas',
      icon: 'upload',
      enableFeature: isImportQuotaActive.value,
      section: SectionName.StockAndQuota,
    })),
  ]

  const transversalParameters = useTransversalParameters()
  const discountParameters = useDiscountAssociationParameters()

  const sections = computed(() => {
    return useParametersFormatter([
      ...transversalParameters,
      ...discountParameters,
      ...productParameters,
      ...offerParameters,
      ...stockParameters,
      ...quotaParameters,
      ...usersParameters,
      ...(window.env.VUE_APP_CONTEXT !== AppContext.episens ? paymentParameters : []),
      ...deliveryParameters,
      ...marketingParameters,
    ])
  })

  return { sections }
}
