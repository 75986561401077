<template>
  <div class="custom-filter-container">
    <div class="alert-banner-container">
      <IdfAlertBanner ref="alertBanner" :kind="alertKind" :autoHide="true">
        {{ currentNotification }}
      </IdfAlertBanner>
    </div>
    <router-view />
  </div>
</template>

<script>
import { ref, provide, defineComponent } from 'vue'
import { IdfAlertBanner } from '@invivodf/ui-kit'

export default defineComponent({
  name: 'VCustomFilters',
  components: { IdfAlertBanner },

  setup() {
    const alertBanner = ref(null)
    const currentNotification = ref('')
    const alertKind = ref('success')

    const handleNotification = (message, type = 'success') => {
      currentNotification.value = message
      alertKind.value = type

      if (alertBanner.value) {
        alertBanner.value.show()
      }
    }

    provide('notificationHandler', handleNotification)

    return {
      alertBanner,
      currentNotification,
      alertKind,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../../../lib/variables';
.alert-banner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  :deep(.idf-alert-banner) {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
</style>
