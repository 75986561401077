
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { useBoToast } from '@invivodf/common/src/application/composables'
import { use18n } from '@invivodf/locales/src/use18n'
import { useSeller } from '@invivodf/common/src/application/composables/useSeller'
import { importAssociations, downloadAssociations } from '../dependency-injection'

const csvFileProperties = {
  name: 'discount_association.csv',
  full_path: '/assets/csv/discount/discount_association.csv',
}
export default {
  name: 'VImportDiscountAssociations',
  components: {
    CImportBase,
    CImportInformation,
  },
  setup() {
    const seller = useSeller()
    const { showNotificationError } = useBoToast()
    const i18n = use18n()

    const associationColumnDetails = [
      {
        header: 'discount_reference',
        format: 'VARCHAR',
        exampleValue: i18n.t('ctx.discount.associations-import.reference-example').toString(),
        description: i18n.t('ctx.discount.associations-import.reference-description').toString(),
        mandatory: i18n.t('ctx.discount.associations-import.yes').toString(),
      },
      {
        header: 'discountable_id',
        format: 'VARCHAR',
        exampleValue: i18n.t('ctx.discount.associations-import.id-example').toString(),
        description: i18n.t('ctx.discount.associations-import.id-description').toString(),
        mandatory: i18n.t('ctx.discount.associations-import.yes').toString(),
      },
    ]

    const associationDescriptionLines = [
      i18n.t('ctx.discount.associations-import.expected-extension').toString(),
      i18n.t('ctx.discount.associations-import.expected-headers').toString(),
      i18n.t('ctx.discount.associations-import.expected-content').toString(),
      i18n.t('ctx.discount.associations-import.expected-encoding').toString(),
    ]

    const importBindingCSV = {
      async import(sellerId, file) {
        try {
          return await importAssociations.execute(sellerId, file)
        } catch (err) {
          showNotificationError(err.message)
          throw err
        }
      },
    }
    const downloadCSV = async () => {
      try {
        return await downloadAssociations.execute(seller.value.id, seller.value.name)
      } catch (err) {
        showNotificationError(err.message)
        throw err
      }
    }

    const importInformation = {
      count: null,
      lastImportAt: null,
    }
    return {
      csvProperties: csvFileProperties,
      csvColumnDetails: associationColumnDetails,
      descriptionLines: associationDescriptionLines,
      importBindingCSV,
      downloadCSV,
      importInformation,
    }
  },
}
