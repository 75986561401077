
import { computed, PropType } from 'vue'
import { IdfInput, IdfStandardInputs } from '@invivodf/ui-kit'
import { IUseDiscountInput } from '../../composables/useDiscountInput'
import { useFormErrors } from '../../composables/useFormErrors'

export default {
  name: 'CDiscountSegmentInput',
  components: {
    IdfStandardInputs,
    IdfInput,
  },
  props: {
    input: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { translateError } = useFormErrors()
    return {
      isSegmentFeatureEnabled: computed(() => window.env.DISCOUNT_SEGMENT_FEATURE === 'yes'),
      translateError,
    }
  },
}
