import { DiscountMeasureUnits } from '@invivodf/discount-sdk/admin'

export const MEASURE_UNITS = {
  DEFAULT: { text: 'ctx.discount.form.select-measure-unit', value: '' },
  ANY: { text: 'ctx.discount.form.any', value: DiscountMeasureUnits.Any },
  TON: { text: 'ctx.discount.form.ton', value: DiscountMeasureUnits.Ton },
  UNIT: { text: 'ctx.discount.form.unit', value: DiscountMeasureUnits.Unit },
  QUINTAL: { text: 'ctx.discount.form.quintal', value: DiscountMeasureUnits.Quintal },
  KILO: { text: 'ctx.discount.form.kg', value: DiscountMeasureUnits.Kilo },
  LITER: { text: 'ctx.discount.form.liter', value: DiscountMeasureUnits.Liter },
} as const
