
import { computed, PropType } from 'vue'
import { use18n } from '@invivodf/locales/src/use18n'

import CDiscountableRow from './CDiscountableRow.vue'
import { Discountable } from '../../../../domain/Discountable'

export default {
  name: 'CDiscountableTable',
  components: { CDiscountableRow },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    discountables: {
      type: Array as PropType<Discountable[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const i18n = use18n()

    const articleToggled = (id) => {
      emit('articleToggled', id)
    }

    const headers = [
      i18n.t('ctx.discount.detail.article-headers.ref').toString(),
      i18n.t('ctx.discount.detail.article-headers.price').toString(),
      i18n.t('ctx.discount.detail.article-headers.valid').toString(),
      i18n.t('ctx.discount.detail.article-headers.conformity').toString(),
      i18n.t('ctx.discount.detail.article-headers.existing-promo').toString(),
    ]
    const uneditableHeaders = headers.filter(
      (header) => header !== i18n.t('ctx.discount.detail.article-headers.existing-promo').toString(),
    )

    return {
      headers: computed(() => (props.editable ? headers : uneditableHeaders)),
      articleToggled,
    }
  },
}
