export const unitLabel = {
  l: 'L',
  kg: 'Kg',
  unit: 'pièce',
  t: 'T',
  q: 'q',
  piece: 'pièce',
}

export default function measureUnit(unit) {
  return (unit && (unitLabel[unit] || unit.charAt(0).toUpperCase() + unit.slice(1))) || ''
}
