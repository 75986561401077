import { computed, ComputedRef, Ref, watch } from 'vue'
import { DiscountUnits } from '@invivodf/discount-sdk/admin'
import { DiscountForm } from '../../domain/discountForm/DiscountForm'
import { getAllowedMeasureUnits } from '../../domain/getAllowedMeasureUnits'
import { useDiscountInput } from './useDiscountInput'
import { FormErrors } from '../../domain/discountForm/FormErrors'

export interface IUseDiscountMeasureUnitInput {
  error: Ref<FormErrors>
  value: Ref<string>
  validate: () => void
  isMeasureUnitDisabled: ComputedRef<boolean>
  measureUnits: Ref<{ text: string; value: string }[]>
  discountForm: Ref<DiscountForm>
}

export const useDiscountMeasureUnitInput = (discountForm: Ref<DiscountForm>): IUseDiscountMeasureUnitInput => {
  const { error, value, validate } = useDiscountInput(discountForm, 'measureUnit')

  const discountFormMeasureUnitValue = computed(() => discountForm.value.measureUnit?.validatedValue)
  watch(discountFormMeasureUnitValue, () => {
    value.value = discountFormMeasureUnitValue.value
  })

  const isMeasureUnitDisabled = computed(
    () =>
      discountForm.value.isMeasureUnitSetToAnyAndNotEditable() ||
      discountForm.value.isMeasureUnitSetToUnitAndNotEditable(),
  )

  const measureUnits = computed(() =>
    getAllowedMeasureUnits(discountForm.value.type, discountForm.value.unit.validatedValue as DiscountUnits),
  )

  value.value = discountForm.value.measureUnit?.validatedValue || measureUnits.value[0].value
  watch(measureUnits, (newVal, oldVal) => {
    if (oldVal !== newVal) value.value = measureUnits.value[0].value
  })

  return {
    error,
    value,
    validate,
    isMeasureUnitDisabled,
    measureUnits,
    discountForm,
  }
}
