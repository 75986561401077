import { RequiredProperty } from './validator/RequiredProperty'
import { FormErrors } from '../FormErrors'

export class RequiredAmount extends RequiredProperty<number | null> {
  constructor(validatedValue: number | null) {
    super(validatedValue, { errorMessage: FormErrors.AmountRequired })
  }

  public validate(unit: string) {
    this.errors = []

    const valid =
      this.validatedValue !== null &&
      typeof this.validatedValue === 'number' &&
      // eslint-disable-next-line no-restricted-globals
      !isNaN(this.validatedValue)
    if (!valid) {
      this.errors.push(FormErrors.AmountInvalid)
    } else {
      if (unit === '%' && this.validatedValue! > 100) {
        this.errors.push(FormErrors.AmountAbove100Percent)
      }
      if (this.validatedValue! <= 0) {
        this.errors.push(FormErrors.AmountNegative)
      }
    }
  }
}
