
import { Big } from 'big.js'
import { format } from 'date-fns'
import { IdfButton } from '@invivodf/ui-kit'
import { currency, measureUnit } from '@/filters'
import { use18n } from '@invivodf/locales/src/use18n'
import CTooltip from '../../CTooltip/CTooltip.vue'
import PInfoMessage from '../../../presenters/PInfoMessage.vue'

export const STATUS_PENDING = 'pending'
export const STATUS_FULFILLED = 'fulfilled'
export const STATUS_TRANSIT = 'transit'
export const STATUS_CANCELED = 'canceled'
export const STATUS_EMPTY = ''

const WISHED_DELIVERY_DATE_FORMAT = 'DD/MM/YYYY'

export default {
  name: 'CLineItemsRow',
  filters: {
    currency,
    measureUnit,
  },
  components: {
    PInfoMessage,
    CTooltip,
    IdfButton,
  },
  props: {
    order: Object,
    displayTracking: Boolean,
  },
  data: () => {
    const i18n = use18n()
    return {
      lineItemStatusesOrder: [STATUS_PENDING, STATUS_TRANSIT, STATUS_FULFILLED, STATUS_EMPTY, STATUS_CANCELED],
      lineItemsGroupAction: [STATUS_PENDING, STATUS_TRANSIT],
      i18n,
    }
  },
  computed: {
    expectedDeliveryDate() {
      return (deliveryDate) => format(deliveryDate, 'DD/MM/YYYY')
    },
    groupedLineItems() {
      const result = {}
      // @ts-ignore  EXPLIQUEZ POURQUOI CE TS IGNORE
      const orderLineItems = this.order.cart && this.order.cart.line_items
      orderLineItems.forEach((item) => {
        const status = item.status || STATUS_EMPTY
        result[status] = (result[status] || []).concat(item)
      })

      const time = (date) => new Date(date).getTime()

      Object.values(result).forEach((lineItems) => {
        // @ts-ignore  EXPLIQUEZ POURQUOI CE TS IGNORE
        lineItems.sort((lineItem1, lineItem2) => {
          if (lineItem1.status === STATUS_TRANSIT) {
            return time(lineItem2.expected_delivery_date) - time(lineItem1.expected_delivery_date)
          }
          if (lineItem1.status === STATUS_FULFILLED) {
            return time(lineItem2.delivery_date) - time(lineItem1.delivery_date)
          }
          return true
        })
      })
      return result
    },
    cityLabel() {
      // @ts-ignore  EXPLIQUEZ POURQUOI CE TS IGNORE
      const { zip_code: zipCode, city } = this.order.shipping.address
      return [zipCode, city].filter(Boolean).join(' ')
    },
    shippingAddress() {
      // @ts-ignore  EXPLIQUEZ POURQUOI CE TS IGNORE
      const { company_name: company, line1, line2, line3 } = this.order.shipping.address
      return [company, line1?.trim(), line2?.trim(), line3?.trim(), this.cityLabel].filter(Boolean).join(' - ')
    },
  },
  methods: {
    hasOneLeastWishedDates(shipping) {
      if (shipping.wished_shipping_date_range) {
        const { start } = shipping.wished_shipping_date_range
        const { end } = shipping.wished_shipping_date_range

        return start || end
      }

      return false
    },
    getWishedShippingDate(shipping) {
      if (shipping.wished_shipping_date_range) {
        const { start } = shipping.wished_shipping_date_range
        const { end } = shipping.wished_shipping_date_range

        if (start && !end) {
          return this.i18n.t(this.ordersLineItemsI18nKey('deliveryDates.starting'), {
            date: format(start, WISHED_DELIVERY_DATE_FORMAT),
          })
        }
        if (!start && end) {
          return this.i18n.t(this.ordersLineItemsI18nKey('deliveryDates.latest'), {
            date: format(end, WISHED_DELIVERY_DATE_FORMAT),
          })
        }
        if (start === end) {
          return this.i18n.t(this.ordersLineItemsI18nKey('deliveryDates.on'), {
            date: format(start, WISHED_DELIVERY_DATE_FORMAT),
          })
        }
        return this.i18n.t(this.ordersLineItemsI18nKey('deliveryDates.between'), {
          from: format(start, WISHED_DELIVERY_DATE_FORMAT),
          to: format(end, WISHED_DELIVERY_DATE_FORMAT),
        })
      }
      return ''
    },
    updateLineItemsStatus(order, lineItems) {
      // @ts-ignore  EXPLIQUEZ POURQUOI CE TS IGNORE
      this.$emit('updateLineItemsStatus', order, lineItems)
    },
    showModalShippingInfos() {
      // @ts-ignore  EXPLIQUEZ POURQUOI CE TS IGNORE
      this.$emit('showModalShippingInfos', this.order)
    },
    displayToast() {
      // @ts-ignore  EXPLIQUEZ POURQUOI CE TS IGNORE
      this.$bvToast.toast('texte copié', {
        autoHideDelay: 1000,
        variant: 'primary',
        noCloseButton: true,
        toaster: 'b-toaster-bottom-right',
      })
    },
    copyToClipboard(id) {
      /*
        Impossible d'utiliser l'API clipboard qui n'est pas supportée sur IE
        (https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API)
      */
      const tempElement = document.createElement('textarea')
      document.body.appendChild(tempElement)
      tempElement.value = id
      tempElement.select()
      document.execCommand('copy')
      document.body.removeChild(tempElement)
      this.displayToast()
    },
    lineItemPricePerMeasureUnitHT(lineItem) {
      const pricePerMeasureUnit = currency(lineItem.unit_price / lineItem.measure_quantity)
      return `${pricePerMeasureUnit} HT/${measureUnit(lineItem.measure_unit)}`
    },
    lineItemPriceTTC(lineItem) {
      return Big(lineItem.total_price).add(lineItem.taxes.tva.total_value)
    },
    lineItemTotalQuantity(lineItem) {
      return `${Big(lineItem.quantity).times(lineItem.measure_quantity)} ${measureUnit(lineItem.measure_unit)}`
    },
    displayPriceDetails(index) {
      this.$emit('showPriceDetailByLineItemTooltip', this.$refs.priceDetailTooltip?.[index])
    },
    displayServiceDetail(index) {
      this.$refs.serviceDetailTooltip?.[index]?.toggleShow?.()
    },
    ordersLineItemsI18nKey(tail: string) {
      return `ctx.orders.lineItems.${tail}`
    },
    ordersPrestaServiceI18nKey(tail: string) {
      return `ctx.orders.prestaService.${tail}`
    },
    isDefined<T>(something: T | undefined): something is NonNullable<T> {
      if (typeof something === 'object') return something !== null
      return typeof something !== 'undefined'
    },
  },
}
