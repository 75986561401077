<template>
  <div class="search container-grey">
    <div class="container">
      <ais-instant-search :search-client="searchClient" :index-name="algoliaIndex" :routing="routing">
        <ais-breadcrumb :attributes="hierarchicalAttributes">
          <adn-breadcrumbs
            :breadcrumbs="breadcrumbsItems"
            slot-scope="{ items, refine }"
            @linkClick="(event, route) => returnToBreadcrumbItem(route, items, refine)"
          />
        </ais-breadcrumb>
        <div class="product-search">
          <div class="search-menus">
            <div>
              <ais-hierarchical-menu
                v-if="showResult"
                :attributes="hierarchicalAttributes"
                :sort-by="compareCategories"
                :limit="100"
              >
                <div slot-scope="{ items, refine }">
                  <ais-stats>
                    <p-hierarchical-menu
                      slot-scope="{ nbHits }"
                      :categories="items"
                      :refine="refine"
                      :nbHits="nbHits"
                    />
                  </ais-stats>
                </div>
              </ais-hierarchical-menu>
              <div v-else class="search-menu__no-category">{{ $t('ctx.products.search-menu__no-category') }}</div>
            </div>
            <div class="search-menu" v-if="isOnCooperativeIndex">
              <div>
                <h2 class="search-menu__title">{{ $t('ctx.products.search-menu__statut') }}</h2>
                <c-instant-search-toggle
                  attribute="is_highlighted"
                  :label="$t('ctx.products.search-menu__highlighted')"
                  :on="true"
                ></c-instant-search-toggle>
                <c-instant-search-toggle
                  attribute="is_highlighted"
                  :label="$t('ctx.products.search-menu__not-highlighted')"
                  :on="false"
                ></c-instant-search-toggle>
                <h2 class="search-menu__title">{{ $t('ctx.products.search-menu__publication') }}</h2>
                <c-instant-search-toggle
                  attribute="is_published"
                  :label="$t('ctx.products.search-menu__published')"
                  :on="true"
                ></c-instant-search-toggle>
                <c-instant-search-toggle
                  attribute="is_published"
                  :label="$t('ctx.products.search-menu__unpublished')"
                  :on="false"
                ></c-instant-search-toggle>
              </div>
            </div>
          </div>
          <div class="search__body">
            <div class="search__catalogs">
              <router-link
                :to="{ name: 'search-cooperative' }"
                class="search__catalog"
                :class="{ 'active-tab': $route.name === 'search-cooperative' }"
                data-test-tab-index
              >
                {{ $t('ctx.products.search-tab__catalog') }}
              </router-link>
              <router-link
                :to="{ name: 'search-national' }"
                class="search__catalog"
                :class="{ 'active-tab': $route.name === 'search-national' }"
                data-test-tab-index
              >
                {{ $t('ctx.products.search-tab__referential-national') }}
              </router-link>
            </div>
            <div v-if="showResult" class="search-catalog-results">
              <h1 class="search-catalog-results__title">
                {{ catalogTitle }}
              </h1>
              <ais-configure v-bind="searchParameters"></ais-configure>

              <ais-search-box ref="searchBox">
                <div slot-scope="{ isSearchStalled, refine }" class="search-catalog-results__search-line">
                  <adn-input
                    type="text"
                    @input="refine($event)"
                    :placeholder="$t('ctx.products.search-catalog-results__placeholder')"
                    class="search-catalog-results__input"
                    :extract-option-value="(payload) => payload.name"
                  />
                  <IdfButton
                    v-if="isOnNationalReferentialIndex"
                    kind="link"
                    @click="downloadNationalReferentialByCooperativeCsvExport"
                    icon-left="upload"
                  >
                    {{ $t('ctx.products.search-catalog-results__search-line-export') }}
                  </IdfButton>
                  <span :hidden="!isSearchStalled">{{
                    $t('ctx.products.search-catalog-results__search-line-loading')
                  }}</span>
                </div>
              </ais-search-box>

              <ais-stats>
                <div slot-scope="{ nbHits }">
                  <ais-current-refinements :included-attributes="hierarchicalAttributes">
                    <div class="search__hit-count" :class="{ 'no-result': nbHits === 0 }" slot-scope="{ items }">
                      {{ resultCount(nbHits, items) }}
                    </div>
                  </ais-current-refinements>

                  <!-- DEVNOTE : no v-if used here to prevent weird behavior with c-instant-search-toggle above -->
                  <ais-clear-refinements v-show="nbHits === 0">
                    <IdfButton
                      v-if="canRefine"
                      class="search-results__reset-filter-button"
                      kind="link"
                      icon-left="close"
                      slot-scope="{ canRefine, refine }"
                      @click.prevent="clearCategories(refine)"
                    >
                      {{ $t('ctx.products.search-results__reset-filter-button') }}
                    </IdfButton>
                  </ais-clear-refinements>
                </div>
              </ais-stats>

              <ais-hits>
                <div slot-scope="{ items }">
                  <div class="search-heading">
                    <div class="search-heading__name">{{ $t('ctx.products.search-heading__name') }}</div>
                    <template v-if="isOnCooperativeIndex">
                      <div class="search-heading__status">
                        {{ $t('ctx.products.search-heading__status-highlighted') }}
                      </div>
                      <div class="search-heading__status">{{ $t('ctx.products.search-heading__status-exclu') }}</div>
                      <div class="search-heading__status">{{ $t('ctx.products.search-heading__status-articles') }}</div>
                    </template>
                  </div>
                  <c-search-item
                    v-for="product in items"
                    :isOnCooperativeIndex="isOnCooperativeIndex"
                    :product="product"
                    :key="product._id"
                  />
                </div>
              </ais-hits>

              <ais-pagination :padding="2" v-on:page-change="onPageChange"></ais-pagination>
            </div>
          </div>
        </div>
      </ais-instant-search>
    </div>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite'
import last from 'lodash/last'
import { simple } from 'instantsearch.js/es/lib/stateMappings'
import { mapGetters } from 'vuex'
import { IdfButton } from '@invivodf/ui-kit'
import { use18n } from '@invivodf/locales/src/use18n'
import { history as historyRouter } from '@/algolia/routers'
import CSearchItem from '@/components/CSearchItem/CSearchItem.vue'
import CInstantSearchToggle from '@/components/CInstantSearchToggle/CInstantSearchToggle.vue'
import PHierarchicalMenu from '@/presenters/search/PHierarchicalMenu/PHierarchicalMenu.vue'
import { productService } from '@/services'
import { CATEGORY_LEVEL_0, CATEGORY_LEVEL_1 } from '@/presenters/search/PAppSearch/constants'

window.env = window.env || {}

export default {
  name: 'VSearch',
  data() {
    return {
      routing: { router: historyRouter(), stateMapping: simple() },
      searchClient: algoliasearch(window.env.ALGOLIA_APP_ID, window.env.ALGOLIA_API_KEY),
      cooperativeReferentialIndexExist: true,
      hierarchicalAttributes: ['categoriesLevel0', 'categoriesLevel1', 'categoriesLevel2', 'categoriesLevel3'],
      cooperativeIndexNotExistOrEmpty: false,
      nationalReferentialByCooperativeIndexNotExistOrEmpty: false,
      appBusinessType: window.env.VUE_APP_CONTEXT,
    }
  },
  components: {
    CSearchItem,
    CInstantSearchToggle,
    PHierarchicalMenu,
    IdfButton,
  },
  computed: {
    ...mapGetters(['cooperativeId', 'selectedCategoryName', 'selectedFamilyName']),
    resultPerPage() {
      return 20
    },
    isOnNationalReferentialIndex() {
      return this.$route.name === 'search-national'
    },
    isOnCooperativeIndex() {
      return this.$route.name === 'search-cooperative'
    },
    showResult() {
      return this.$route.name === 'search-cooperative-referential' ? this.cooperativeReferentialIndexExist : true
    },
    breadcrumbsItems() {
      const i18n = use18n()
      const breadcrumbsItems = [
        {
          name: `${i18n.t('breadcrumbs.home').toString()}`,
          route: { name: 'home' },
        },
        {
          name: this.catalogTitle,
          route: { name: this.$route.name },
        },
      ]
      const routeQuery = {}
      if (this.selectedFamilyName) {
        routeQuery[CATEGORY_LEVEL_0] = this.selectedFamilyName
        breadcrumbsItems.push({
          name: this.selectedFamilyName,
          route: {
            name: this.$route.name,
            query: { ...routeQuery },
          },
        })
        if (this.selectedCategoryName) {
          breadcrumbsItems.push({
            name: this.selectedCategoryName,
            route: {
              name: this.$route.name,
              query: {
                ...routeQuery,
                [CATEGORY_LEVEL_1]: this.selectedCategoryName,
              },
            },
          })
        }
      }
      return breadcrumbsItems
    },
    algoliaIndex() {
      const i18n = use18n()
      const { locale } = i18n
      switch (this.$route.name) {
        case 'search-cooperative':
          return this.cooperativeIndexNotExistOrEmpty
            ? `${window.env.ALGOLIA_INDEX_COOP_PREFIX + this.cooperativeId}.${locale}.old`
            : `${window.env.ALGOLIA_INDEX_COOP_PREFIX + this.cooperativeId}.${locale}`
        case 'search-cooperative-referential':
          return this.cooperativeReferentialIndexExist
            ? `${window.env.ALGOLIA_REFERENTIAL_COOP_PREFIX + this.cooperativeId}.${locale}`
            : `${window.env.ALGOLIA_INDEX}.${locale}`
        default:
          return this.nationalReferentialByCooperativeIndexNotExistOrEmpty
            ? `${window.env.ALGOLIA_INDEX_NATIONAL_COOP_PREFIX + this.cooperativeId}.${locale}.old`
            : `${window.env.ALGOLIA_INDEX_NATIONAL_COOP_PREFIX + this.cooperativeId}.${locale}`
      }
    },
    catalogTitle() {
      const i18n = use18n()
      let catalogTitle
      switch (this.$route.name) {
        case 'search-cooperative':
          catalogTitle = `${i18n.t('ctx.products.search-cooperative__title').toString()}`
          break
        case 'search-cooperative-referential':
          catalogTitle = `${i18n.t('ctx.products.search-cooperative-referential__title').toString()}`
          break
        default:
          catalogTitle = `${i18n.t('ctx.products.search-default__title').toString()}`
      }
      return catalogTitle
    },
    searchParameters() {
      const parameters = {
        hitsPerPage: this.resultPerPage,
        disjunctiveFacets: ['organic', 'is_off_season', 'is_highlighted', 'is_published'],
      }
      if (this.$route.name === 'search-cooperative-referential') {
        return parameters
      }
      return parameters
    },
  },
  async created() {
    const i18n = use18n()
    const { locale } = i18n
    const coopReferentialIndex = this.searchClient.initIndex(
      `${window.env.ALGOLIA_REFERENTIAL_COOP_PREFIX}${this.cooperativeId}.${locale}`,
    )
    try {
      await coopReferentialIndex.search('')
    } catch (e) {
      this.cooperativeReferentialIndexExist = false
    }
    const coopIndex = this.searchClient.initIndex(
      `${window.env.ALGOLIA_INDEX_COOP_PREFIX}${this.cooperativeId}.${locale}`,
    )
    try {
      const { nbHits } = await coopIndex.search('')
      this.cooperativeIndexNotExistOrEmpty = !nbHits
    } catch (e) {
      this.cooperativeIndexNotExistOrEmpty = true
    }
    const nationalReferentialByCooperativeIndex = this.searchClient.initIndex(
      `${window.env.ALGOLIA_INDEX_NATIONAL_COOP_PREFIX}${this.cooperativeId}.${locale}`,
    )
    try {
      const { nbHits } = await nationalReferentialByCooperativeIndex.search('')
      this.nationalReferentialByCooperativeIndexNotExistOrEmpty = !nbHits
    } catch (e) {
      this.nationalReferentialByCooperativeIndexNotExistOrEmpty = true
    }

    // FIXME: extend to nested categories (@See CHeaderCategoryMenu.html)
    if (this.$route.query[CATEGORY_LEVEL_0]) {
      this.$store.dispatch('setFamily', this.$route.query[CATEGORY_LEVEL_0])
      if (this.$route.query[CATEGORY_LEVEL_1]) {
        this.$store.dispatch('setCategory', this.$route.query[CATEGORY_LEVEL_1])
      }
    }
  },
  methods: {
    onPageChange() {
      window.scrollTo(0, 0)
    },
    resultCount(count, refinedHierarchicalAttributes) {
      const i18n = use18n()
      if (count <= 0) {
        if (refinedHierarchicalAttributes && refinedHierarchicalAttributes.length > 0) {
          return `${i18n.t('ctx.products.search-results-count__no-results').toString()}${this.currentCategoryText(refinedHierarchicalAttributes)}`
        }
        return `${i18n.t('ctx.products.search-results-count__no-results').toString()}`
      }
      if (count === 1) {
        return `${i18n.t('ctx.products.search-results-count__one-result').toString()}`
      }
      return `${count} ${i18n.t('ctx.products.search-results-count__results').toString()}`
    },
    currentCategoryText(refinedHierarchicalAttributes) {
      const i18n = use18n()
      const categoryRefinement = refinedHierarchicalAttributes.find((item) => item.attribute === 'categoriesLevel0')
      if (categoryRefinement) {
        const categoryLabels = categoryRefinement.refinements[0].label.split(' > ')
        return `${i18n.t('ctx.products.search__current-category-text').toString()} ${last(categoryLabels)}`
      }
      return ''
    },
    compareCategories(firstCategory, secondCategory) {
      if (firstCategory.name === 'Autres') return 1
      if (secondCategory.name === 'Autres') return -1
      return firstCategory.name.localeCompare(secondCategory.name)
    },

    async downloadNationalReferentialByCooperativeCsvExport() {
      const response = await productService.downloadNationalReferentialCsvProductsByCooperative(this.cooperativeId)
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([response.data]))
      link.setAttribute('download', 'national_referential_export.csv')
      document.body.appendChild(link)
      link.click()
    },
    returnToBreadcrumbItem(route, hierarchicalAttributesItems, hierarchicalAttributesRefine) {
      const routeQuery = route.query || {}
      if (!routeQuery[CATEGORY_LEVEL_0]) {
        hierarchicalAttributesRefine(null)
      } else if (
        hierarchicalAttributesItems &&
        hierarchicalAttributesItems.length > 0 &&
        hierarchicalAttributesItems[0].value
      ) {
        hierarchicalAttributesRefine(hierarchicalAttributesItems[0].value)
      }
      this.$store.dispatch('setFamily', routeQuery[CATEGORY_LEVEL_0] || '')
      this.$store.dispatch('resetSelectedCategory')
    },
    clearCategories(refine) {
      this.$store.dispatch('resetSelectedFamily')
      this.$store.dispatch('resetSelectedCategory')
      refine()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../lib/_variables.scss';
.search {
  padding: 2rem 0 6rem;
  .adn-breadcrumbs {
    margin-bottom: 2rem;
  }
}
.product-search {
  display: flex;
}
.search__body {
  margin-left: 30px;
  width: 100%;
}
.search-no-custom {
  border: 1px solid var(--idf-color-neutral-200);
  padding: 24px 20px;
}
.search-no-custom__btn {
  display: block;
  width: fit-content;
  margin: 21px auto 0;
}
.search-heading {
  position: relative;
  z-index: 1;
  font-size: 0.9em;
  line-height: 40px;
  border-top: 2px solid var(--idf-color-neutral-200);
  border-bottom: 2px solid var(--idf-color-neutral-200);
  color: var(--idf-color-neutral-400);
  display: flex;
  &__name {
    margin-left: 88px;
    width: 270px;
  }
  &__status {
    max-width: 186px;
    flex: 2;
    margin-left: 10px;
    display: flex;
    justify-content: center;
  }
}
.search__catalogs {
  display: flex;
}
.search-catalog-results {
  border: 1px solid var(--idf-color-neutral-200);
  padding: 24px 20px;
  background-color: var(--idf-color-neutral-100);
}
.search-catalog-results__title {
  font-weight: bold;
  margin-bottom: 25px;
  font-size: 28px;
}
.search-catalog-results__search-line {
  display: flex;
  justify-content: space-between;
}
.search-catalog-results__input {
  font-size: 1.6rem;
  width: unset;
  flex-grow: 1;
  margin-right: 20px;
}
.search__catalog {
  display: flex;
  font-size: 1em;
  border: 1px solid var(--idf-color-neutral-200);
  color: var(--idf-color-neutral-500);
  min-width: 200px;
  text-align: center;
  min-height: 30px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 -10px 10px -10px rgba(0, 0, 0, 0.2) inset;
  background-color: var(--idf-color-neutral-100);
  &:hover {
    color: black;
    text-decoration: none;
  }
  &.active-tab {
    border: 1px solid var(--idf-color-main-400);
    background-color: var(--idf-color-main-400);
    color: white;
    font-weight: bold;
  }
}
.search-menus {
  min-width: 270px;
  .search-menu:not(:first-child) {
    margin-top: 18px;
    padding-bottom: 3rem;
  }
}
.search-menu {
  width: 270px;
  border: 1px solid var(--idf-color-neutral-200);
  background-color: var(--idf-color-neutral-100);
  padding: 10px;
}
.search-menu__no-category {
  color: var(--idf-color-neutral-400);
  font-size: 16px;
  padding: 10px;
}
.search-menu__title {
  text-transform: uppercase;
  color: var(--idf-color-neutral-500);
  font-size: 0.8em;
  padding: 10px 10px 0;
  font-weight: 500; // due to aladin reboot
  margin-bottom: 0;
}
.search__hit-count.no-result {
  margin-left: 12px;
  color: var(--idf-color-error-400);
  font-size: 1.6rem;
}

@media (max-width: 767.98px) {
  .search__catalogs {
    text-align: center;
  }
  .search-menu {
    border-right: 0;
  }
  .search-menu__title {
    padding-left: 0;
  }
  .search-menu-list__family-item {
    padding-left: 0;
    padding-right: 0;
  }
  .search-menu-list__family-block ul {
    margin: 0;
  }
  .search-item__image-wrapper {
    margin-left: 0;
  }
  .search-item__description {
    margin-left: 20px;
  }
  .search-heading {
    height: auto;
  }
  .search-heading__highlight {
    margin-left: 0;
  }
}
</style>
<style lang="scss" src="./VSearch-ais.scss"></style>
