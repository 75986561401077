
import { IdfTextIcon } from '@invivodf/ui-kit'
import { defineComponent, PropType } from 'vue'
import { DiscountPeriodStatusEnum } from '../../domain/statusByPeriod'

const PeriodStatusIcon = {
  [DiscountPeriodStatusEnum.EXPIRED]: {
    style: 'status-expired',
    icon: 'hourglass-bottom-circle-filled',
    text: 'ctx.discount.status.expired',
  },
  [DiscountPeriodStatusEnum.UPCOMING]: {
    style: 'status-upcoming',
    icon: 'clock-outlined',
    text: 'ctx.discount.status.upcoming',
  },
  [DiscountPeriodStatusEnum.ACTIVE]: {
    style: 'status-active',
    icon: 'check-circle-filled',
    text: 'ctx.discount.status.active',
  },
}

export default defineComponent({
  name: 'CPeriodStatusIcon',
  components: {
    IdfTextIcon,
  },
  props: {
    status: {
      type: String as PropType<DiscountPeriodStatusEnum>,
      required: true,
    },
  },
  setup({ status }) {
    const { style, icon, text } = PeriodStatusIcon[status]

    return { style, icon, text }
  },
})
