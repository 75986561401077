<template>
  <div class="container">
    <span class="disclaimer">
      {{ $t('ctx.produits.product-review-description') }}
    </span>
    <c-product-review
      :review="(product.cooperativeReview && product.cooperativeReview.upsides) || ''"
      title="Les plus"
      placeholder="Ecrivez ici les plus de ce produit"
      @save-review="saveUpsides"
      @delete-review="deleteUpsides"
      data-test-product-upsides
    ></c-product-review>
    <c-product-review
      :review="(product.cooperativeReview && product.cooperativeReview.tips) || ''"
      title="Les conseils d'utilisation"
      placeholder="Ecrivez ici vos conseils d'utilisation pour ce produit"
      @save-review="saveTips"
      @delete-review="deleteTips"
      data-test-product-tips
    ></c-product-review>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import CProductReview from '../CProductReview/CProductReview.vue'

export default {
  name: 'CProductReviews',
  components: {
    CProductReview,
  },
  props: ['product'],
  methods: {
    saveUpsides(upsides) {
      const review = {
        productId: this.product.id,
        upsides,
      }
      this.$store.dispatch('saveProductUpsides', review)
    },
    deleteUpsides() {
      this.$store.dispatch('deleteProductUpsides', this.product.id)
    },
    saveTips(tips) {
      const review = {
        productId: this.product.id,
        tips,
      }
      this.$store.dispatch('saveProductTips', review)
    },
    deleteTips() {
      this.$store.dispatch('deleteProductTips', this.product.id)
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
.disclaimer {
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: var(--idf-color-neutral-500);
}
</style>
