import { useAuthService } from '@b2ag/auth'
import { createPartnerArticleApiClient } from '@aladin/shared-universcoop-sdk'
import { createGetAnchoredArticlesUseCase } from '@/contexts/articles/usecase/get-anchored-articles.usecase'
import { createCreateArticleUsecase } from './usecase/create-article.usecase'
import { createArticleRepository } from './infrastructure/article.repository'
import { createGetArticlesUseCase } from './usecase/get-articles.usecase'
import { createGetOneArticleUseCase } from './usecase/get-one-article-usecase'
import { createUpdateArticleUsecase } from './usecase/update-article.usecase'
import { createDeleteArticleUseCase } from './usecase/delete-article.usecase'

const articleApiHost = window.env?.ARTICLE_API_HOST ?? ''

const articleClient = createPartnerArticleApiClient({
  partnerArticleApiHost: articleApiHost,
  accessTokenSource: () => useAuthService().getAccessToken(),
})

const articleRepository = createArticleRepository({ articleClient })

const createArticleUsecase = createCreateArticleUsecase({ articleRepository })
const getArticlesUseCase = createGetArticlesUseCase({ articleRepository })
const getAnchoredArticlesUseCase = createGetAnchoredArticlesUseCase({ articleRepository })
const getOneArticleUsecase = createGetOneArticleUseCase({ articleRepository })
const updateArticleUsecase = createUpdateArticleUsecase({ articleRepository })
const deleteArticleUsecase = createDeleteArticleUseCase({ articleRepository })

export {
  createArticleUsecase,
  getArticlesUseCase,
  getAnchoredArticlesUseCase,
  getOneArticleUsecase,
  updateArticleUsecase,
  deleteArticleUsecase,
}
