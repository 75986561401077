
import { computed, defineComponent, ref, watch } from 'vue'
import { IdfSelect } from '@invivodf/ui-kit'
import { use18n } from '@invivodf/locales/src/use18n'
import { useAuthorizedDiscountPolicies } from '../../composables/useAuthorizedDiscountPolicies'

export default defineComponent({
  name: 'CSelectFilterType',
  components: { IdfSelect },
  setup(props, { emit }) {
    const i18n = use18n()

    const { authorizedDiscountTypes: discountTypes } = useAuthorizedDiscountPolicies()
    const discountType = ref('')

    const isOnlyOneTypeAvailable = computed(() => discountTypes.value.length === 1)

    const items = computed(() => {
      const typeItems: { value: string; label: string }[] = discountTypes.value.map((item) => ({
        value: item.value,
        label: i18n.t(item.text).toString(),
      }))

      if (isOnlyOneTypeAvailable.value) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        discountType.value = discountTypes.value[0].value
      } else {
        typeItems.unshift({
          value: '',
          label: i18n.t('ctx.discount.listing.discount-type-filter-placeholder').toString(),
        })
      }

      return typeItems
    })

    watch(discountType, () => {
      if (!isOnlyOneTypeAvailable.value) {
        emit('input', discountType.value)
      }
    })

    return { discountType, items }
  },
})
