import createProductSdk from '@b2ag/module-product-sdk'
import { useAuthService } from '@invivodf/common/src/auth'
import type { InvivoLocale } from '@invivodf/locale'
import { buildCallerId } from '@invivodf/common/src/core/caller-id/caller-id'
import { ProductSdkCustomFilterRepository } from './infrastructure/product-sdk-custom-filter.repository'
import { GetPartnerCustomFiltersUsecase } from './usecases/get-partner-custom-filters.usecase/get-partner-custom-filters.usecase'
import { productApiHost } from './config'
import { UpdatePartnerCustomFiltersUsecase } from './usecases/update-partner-custom-filters.usecase/update-partner-custom-filters.usecase'
import { DeletePartnerCustomFiltersUsecase } from './usecases/delete-partner-custom-filters.usecase/delete-partner-custom-filters.usecase'
import { GetOnePartnerCustomFiltersUseCase } from './usecases/get-one-partner-custom-filters.usecase/get-one-partner-custom-filters.usecase'

const accessTokenSource = () => useAuthService().getAccessToken()

export const productSdk = createProductSdk(productApiHost, {
  accessTokenSource,
  localeSource: () => 'fr-FR' as InvivoLocale,
  callerId: buildCallerId(),
})

const partnerCustomFiltersRepository = new ProductSdkCustomFilterRepository(productSdk)
const getPartnerCustomFilterUseCase = new GetPartnerCustomFiltersUsecase(partnerCustomFiltersRepository)
const updatePartnerCustomFiltersUsecase = new UpdatePartnerCustomFiltersUsecase(partnerCustomFiltersRepository)
const deletePartnerCustomFiltersUseCase = new DeletePartnerCustomFiltersUsecase(partnerCustomFiltersRepository)
const getOnePartnerCustomFiltersUseCase = new GetOnePartnerCustomFiltersUseCase(partnerCustomFiltersRepository)

export {
  getPartnerCustomFilterUseCase,
  getOnePartnerCustomFiltersUseCase,
  updatePartnerCustomFiltersUsecase,
  deletePartnerCustomFiltersUseCase,
}
