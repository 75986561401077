import { render, staticRenderFns } from "./CDiscountWithAmountApplication.vue?vue&type=template&id=4506383c&scoped=true"
import script from "./CDiscountWithAmountApplication.vue?vue&type=script&lang=ts"
export * from "./CDiscountWithAmountApplication.vue?vue&type=script&lang=ts"
import style0 from "./CDiscountWithAmountApplication.vue?vue&type=style&index=0&id=4506383c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4506383c",
  null
  
)

export default component.exports