
import { computed, defineComponent, inject, onMounted, ref } from 'vue'
import { IdfBreadcrumb, IdfButton } from '@invivodf/ui-kit'
import { use18n } from '@invivodf/locales/src/use18n'
import { useRouter } from '@invivodf/common/src/application/composables/useRouter'
import { CustomFilter } from '@b2ag/module-product-sdk'
import CCustomFiltersTable from '@/contexts/custom-filters/application/components/CCustomFiltersTable/CCustomFiltersTable.vue'
import { useCustomFilter } from '@/contexts/custom-filters/application/composable/useCustomFilter'

export default defineComponent({
  name: 'CCustomFilters',
  components: { CCustomFiltersTable, IdfBreadcrumb, IdfButton },

  setup() {
    const i18n = use18n()
    const router = useRouter()
    const { getPartnerCustomFilters, deletePartnerCustomFilters } = useCustomFilter()
    const filters = ref<CustomFilter[]>([])
    // eslint-disable-next-line no-unused-vars
    const notificationHandler = inject<(message: string, type?: string) => void>('notificationHandler')

    const breadcrumbsItems = [
      { label: i18n.t('breadcrumbs.home'), link: { name: 'home' }, icon: 'home-filled' },
      { label: i18n.t('breadcrumbs.parameters'), link: { name: 'parameters' } },
      { label: i18n.t('ctx.custom-filters.list-title') },
    ]

    const fetchFilters = async () => {
      try {
        filters.value = await getPartnerCustomFilters()
      } catch (error) {
        if (notificationHandler) {
          notificationHandler(i18n.t('ctx.custom-filters.fetch-error').toString(), 'danger')
        }
        filters.value = []
      }
    }

    const isCustomFiltersEmpty = computed(() => {
      return filters.value.length === 0
    })
    const goToCreation = () => {
      router.push({ name: 'custom-filters-create' })
    }
    const goToEdit = (category: string, crop: string): void => {
      router.push({
        name: 'custom-filters-edit',
        params: { category, crop },
      })
    }

    const handleDelete = async (filter: CustomFilter) => {
      try {
        await deletePartnerCustomFilters(filter.categoryId, filter.cropId)
        await fetchFilters()
        if (notificationHandler) {
          notificationHandler(i18n.t('ctx.custom-filters.delete-success').toString())
        }
      } catch (error) {
        if (notificationHandler) {
          notificationHandler(i18n.t('ctx.custom-filters.delete-error').toString(), 'danger')
        }
      }
    }

    onMounted(async () => {
      await fetchFilters()
    })

    return {
      breadcrumbsItems,
      filters,
      goToCreation,
      goToEdit,
      handleDelete,
      isCustomFiltersEmpty,
    }
  },
})
