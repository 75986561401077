export const FACETS_LABELS = {
  'variants.anti_crows_oad': 'Anti-corbeaux',
  'variants.crop_protection_label': 'Gestion Traitement de semences',
  'variants.height_mm': 'Hauteur en mm',
  'variants.label_certification': 'Label / certification',
  'variants.length_mm': 'Longueur en mm',
  'variants.mesh_size_mm': 'Maille en mm',
  'variants.precocity': 'Précocité',
  'variants.section_mm': 'Section en mm',
  'variants.surface': 'Surface en m2',
  'variants.width_mm': 'Largeur en mm',
  active_substances: 'Matières actives',
  activity_area: "Surface d'activité",
  alternation: 'Alternativité',
  aristation: 'Aristation',
  aristation_oad: 'Aristation OAD',
  ball_type: 'Type de balle',
  color: 'Couleur',
  crop_types: 'Type de culture',
  crops: 'Cultures',
  destination: 'Destination',
  flea_beetle_behaviour_oad: 'Comportement face aux altises',
  has_discount: 'Promotions',
  is_biodegradable: 'Biodégradable',
  is_highlighted: 'Recommandé',
  is_off_season: 'Offres du moment',
  knot_resistance: 'Résistance aux nœuds',
  material: 'Matière',
  number_of_ranks: 'Nombre de rangs',
  nutritive_elements: 'Eléments nutritifs',
  organic: 'Agriculture biologique',
  orobranch_oad_risk: 'Risque Orobanche',
  precocity_corn_group: 'Précocité groupe',
  precocity_group_oad: 'Précocité Groupe',
  precocity_with_spike: 'Précocité à épiaison',
  product_type: 'Type de produit',
  profile: 'Profil génétique',
  resistance_oad_herbicide: 'Résistance à un herbicide',
  risk_flea_beetles_oad: 'Risque Altises',
  risk_replacement_hernia_oad: 'Risque Hernie du chou',
  soil_potential_oad: 'Potentiel de sol',
  specificity: 'Spécificité produit',
  supplier_refinement: 'Marques',
  tear_resistance: 'Résistance au déchirement',
  thickness: 'Epaisseur en mm',
  weight_in_gramm_per_square_meter: 'Grammage en g/m2',
}
