import { inject, onBeforeMount, provide, Ref, ref } from 'vue'
import uniq from 'lodash/uniq'
import keyBy from 'lodash/keyBy'
import { useBoToast } from '@invivodf/common/src/application/composables'
import { retrieveAuthorizedDiscountPolicies } from '../../dependency-injection'
import { DISCOUNT_TYPES_TEXT, DisplayedDiscountType } from '../../domain/constants/DISCOUNT_TYPES'
import { useSeller } from '../../../../application/composables/useSeller'
import { DiscountPolicy } from '../../domain/DiscountPolicy'
import { DisplayedDiscountPolicies, DISCOUNT_POLICIES_BY_ID } from '../../domain/constants/DISCOUNT_POLICIES_BY_ID'

export const discountPoliciesSymbol = Symbol('discountPolicies')

const discountTypeByValue = keyBy(Object.values(DISCOUNT_TYPES_TEXT), 'value')

const discountPoliciesToDisplayedDiscountTypes = (discountPolicies: DiscountPolicy[]): DisplayedDiscountType[] => {
  return uniq(discountPolicies.map((discountPolicy) => discountTypeByValue[discountPolicy.type]))
}
const discountPoliciesToDisplay = (discountPolicies: DiscountPolicy[]): DisplayedDiscountPolicies[] => {
  return discountPolicies.map((discountPolicy) => DISCOUNT_POLICIES_BY_ID[discountPolicy.id])
}

export const loadAuthorizedDiscountPolicies = (
  authorizedDiscountTypes: Ref<DisplayedDiscountType[]>,
  authorizedDiscountPolicies: Ref<DisplayedDiscountPolicies[]>,
  isDiscountPoliciesLoading: Ref<Boolean>,
) => {
  onBeforeMount(async () => {
    const seller = useSeller()
    const { showNotificationError } = useBoToast()

    try {
      const discountPolicies = await retrieveAuthorizedDiscountPolicies.execute(seller.value.id)
      authorizedDiscountTypes.value = discountPoliciesToDisplayedDiscountTypes(discountPolicies)
      authorizedDiscountPolicies.value = discountPoliciesToDisplay(discountPolicies)
      isDiscountPoliciesLoading.value = false
    } catch (err) {
      showNotificationError(err.message)
    }
  })
}

export const provideAuthorizedDiscountPolicies = () => {
  const authorizedDiscountTypes: Ref<DisplayedDiscountType[]> = ref([])
  const authorizedDiscountPolicies: Ref<DisplayedDiscountPolicies[]> = ref([])
  const isDiscountPoliciesLoading = ref(true)

  loadAuthorizedDiscountPolicies(authorizedDiscountTypes, authorizedDiscountPolicies, isDiscountPoliciesLoading)

  provide(discountPoliciesSymbol, { authorizedDiscountTypes, authorizedDiscountPolicies })

  return { isDiscountPoliciesLoading }
}

interface IUseAuthorizeDiscountPolicies {
  authorizedDiscountTypes: Ref<DisplayedDiscountType[]>
  authorizedDiscountPolicies: Ref<DisplayedDiscountPolicies[]>
}

export const useAuthorizedDiscountPolicies = (): IUseAuthorizeDiscountPolicies => {
  const injectedDiscountPolicies = inject<{
    authorizedDiscountTypes: Ref<DisplayedDiscountType[]>
    authorizedDiscountPolicies: Ref<DisplayedDiscountPolicies[]>
  }>(discountPoliciesSymbol)

  if (!injectedDiscountPolicies) throw new Error('Discount policies not loaded')

  return injectedDiscountPolicies
}
