import axios, { AxiosInstance } from 'axios'
import { useAuthService } from '@b2ag/auth'

const NO_TIMEOUT = 0

export interface Options {
  timeout?: number
  accept?: string
}

export function createAxiosClientAnonymous(
  baseUrl: string,
  { timeout = NO_TIMEOUT, accept = 'application/json' }: Options = {},
) {
  return axios.create({
    baseURL: `${baseUrl}`,
    timeout,
    headers: {
      Accept: accept,
      'aladin-front': 'seller',
    },
  })
}

export function createAxiosClientWithAladinBearer(baseUrl: string, options: Options = {}): AxiosInstance {
  const client = createAxiosClientAnonymous(baseUrl, options)
  client.interceptors.request.use(async (config) => {
    const authService = useAuthService()
    const accessToken = await authService.getAccessToken()
    if (accessToken !== null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  })
  return client
}

export function createAxiosClientWithLocalStorage(baseUrl: string, options: Options = {}): AxiosInstance {
  const client = createAxiosClientAnonymous(baseUrl, options)
  client.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('access_token')
    if (accessToken !== null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  })
  return client
}
