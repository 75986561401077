
import { computed, defineComponent, Ref, ref } from 'vue'
import { use18n } from '@invivodf/locales/src/use18n'
import { download } from '@invivodf/common/src/infrastructure/download.service'
import { IdfButton } from '@invivodf/ui-kit'
import CFileUpload from '../CFileUpload.vue'
import CFileDownload from '../CFileDownload.vue'
import PDiscountConfirmationModalFooter from '../presenters/PDiscountConfirmationModalFooter.vue'
import { validateMetaDiscountAssociations } from '../../../dependency-injection'
import { useLoadedForm } from '../../composables/useLoadedForm'

export default defineComponent({
  name: 'CMetaDiscountsAssociationsModal',
  components: {
    PDiscountConfirmationModalFooter,
    IdfButton,
    CFileUpload,
    CFileDownload,
  },
  setup(_, { emit }) {
    const i18n = use18n()

    const form = useLoadedForm()
    const newAssociationsFile = ref<File | null>(null)
    const newAssociationsCount = ref<number | null>(null)

    const discountablesImportErrors: Ref<string[]> = ref([])

    const isLoading = ref(false)
    const validateNewAssociations = async (e: { target: { value: string; files: FileList | null } }) => {
      const { files } = e.target
      if (!files || !files.item(0)) return
      const file = files.item(0)
      if (!file) return
      isLoading.value = true
      const { totalCount, errors } = await validateMetaDiscountAssociations.execute(
        form.value.reference.validatedValue,
        file,
      )
      if (errors.length) {
        discountablesImportErrors.value = errors.map(
          ({ message, row }) => `${i18n.t('ctx.discount.form.line')} ${row}: ${message}`,
        )
      } else {
        newAssociationsFile.value = file
        newAssociationsCount.value = totalCount
      }
      // eslint-disable-next-line no-param-reassign
      e.target.value = ''
      isLoading.value = false
    }

    const downloadNewDiscountables = () => {
      download(newAssociationsFile.value, 'discount_associations.csv')
    }

    const importedDiscountablesMessage = computed(
      () => `${newAssociationsCount.value} ${i18n.t('ctx.discount.form.imported-ids')}`,
    )

    const validate = () => {
      emit('validate', { file: newAssociationsFile.value, count: newAssociationsCount.value })
      newAssociationsFile.value = null
      newAssociationsCount.value = null
    }

    const cancel = () => {
      emit('cancel')
      newAssociationsFile.value = null
      newAssociationsCount.value = null
    }

    return {
      newAssociationsFile,
      discountablesImportErrors,
      validateNewAssociations,
      downloadNewDiscountables,
      importedDiscountablesMessage,
      validate,
      cancel,
      isLoading,
    }
  },
})
