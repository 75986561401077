
import { toRefs, computed, defineComponent, PropType } from 'vue'
import { DiscountScopes, DiscountTypes, DiscountUnits } from '@invivodf/discount-sdk/admin'
import { useContext } from '../../../../application/composables/useContext'
import { formattedMeasureUnits } from '../../domain/formattedMeasureUnits'

export default defineComponent({
  name: 'CDiscountAmountDetail',
  props: {
    amount: Number,
    unit: String as PropType<DiscountUnits>,
    measureUnit: String as PropType<string | null>,
    scope: String as PropType<DiscountScopes>,
    type: String as PropType<DiscountTypes>,
    groupSize: {
      type: Number as PropType<number | null>,
      required: false,
    },
  },
  setup(props) {
    const { scope, type, measureUnit } = toRefs(props)

    const { isB2ag } = useContext()

    const isScopeGroup = computed(() => scope.value === DiscountScopes.Group)
    const isTypeFixedPrice = computed(() => type.value === DiscountTypes.FixedPrice)
    const shouldDisplayMeasureUnit = computed(
      () => isB2ag.value || measureUnit.value !== formattedMeasureUnits.unit.single,
    )

    return { isScopeGroup, isTypeFixedPrice, shouldDisplayMeasureUnit }
  },
})
