import { RequiredProperty } from './validator/RequiredProperty'
import { FormErrors } from '../FormErrors'

export class NotRequiredPromoCode extends RequiredProperty<string | null> {
  constructor(validatedValue: string | null) {
    super(validatedValue, { errorMessage: FormErrors.NullablePromoCodeRequired })
  }

  validate() {
    this.errors = []
    if (NotRequiredPromoCode.hasValue(this.validatedValue)) {
      if (this.validatedValue.length > 20) {
        this.errors.push(FormErrors.NullablePromoCodeTooLong)
      }
      if (!/^[A-Z\d]+$/g.test(this.validatedValue)) {
        this.errors.push(FormErrors.NullablePromoCodeInvalidCharacters)
      }
    }
  }

  private static hasValue(value): value is string {
    return value !== null && value?.length !== 0
  }
}
