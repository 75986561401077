<template>
  <div>
    <c-import-base
      :importFileService="cooperativeVariantService"
      :csvProperties="csvProperties"
      :pageTitle="pageTitle"
      v-bind:uploadButtonLabel="uploadButtonLabel"
      :csvColumnDetails="csvColumnDetails"
      :reportWarning="reportWarning"
      :descriptionLines="descriptionLines"
    >
      <c-import-information
        :title="title"
        :nbResultText="nbResultText"
        :exportText="exportText"
        :importInformation="importInformation"
        :downloadCSV="downloadCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { AppContext } from '@invivodf/common/app.context'
import { use18n } from '@invivodf/locales/src/use18n'
import { useStore } from '@/composables/useStore'
import { cooperativeVariantService } from '../../services'

export default defineComponent({
  name: 'CImportVariants',
  components: {
    CImportBase,
    CImportInformation,
  },
  setup() {
    const store = useStore()
    const i18n = use18n()

    const uploadButtonLabel = computed(() => {
      return `${i18n.t('ctx.import.variants__upload-button-label').toString()} ${i18n.t('message.partner')}${i18n.t('ctx.import.variants__upload-button-label-2').toString()}`
    })
    const pageTitle = computed(() => {
      return `${i18n.t('ctx.import.variants__page-title').toString()}`
    })
    const reportWarning = computed(() => {
      return i18n.t('ctx.import.variants__report-warning').toString()
    })
    const title = computed(() => {
      return i18n.t('ctx.import.variants__information-title').toString()
    })
    const nbResultText = computed(() => {
      return i18n.t('ctx.import.variants__information-result-number').toString()
    })
    const exportText = computed(() => {
      return i18n.t('ctx.import.variants__information-export-text').toString()
    })
    const csvProperties = ref({
      name: 'variants.csv',
      full_path: '/assets/csv/variants.csv',
    })

    const importInformation = ref({
      count: null,
      lastImportAt: null,
    })

    const csvColumnDetails = ref([])

    const descriptionLines = [
      `${i18n.t('ctx.import.common__description-line-1')}<b>.csv</b>`,
      `${i18n.t('ctx.import.common__description-line-2')}<code>;</code>.`,
      `${i18n.t('ctx.import.common__description-line-3')}`,
      `${i18n.t('ctx.import.common__description-line-4')}<b>UTF8</b>.`,
    ]

    const cooperativeId = computed(() => store.getters.cooperativeId)
    const cooperative = computed(() => store.getters.cooperative)

    const downloadCSV = () => {
      return cooperativeVariantService.downloadVariants(cooperativeId.value, cooperative.value.name)
    }

    const initializeCsvColumnDetails = async () => {
      csvColumnDetails.value = [
        {
          header: 'assortment_product_uid',
          format: 'VARCHAR',
          exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')}<code>INV6CFIRXF1S9LAWPHY</code>`,
          description: `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-product-id-description-1')}</strong> <br>${i18n.t('ctx.import.variants__csv-column-details__assortment-product-id-description-1')}`,
          mandatory: `${i18n.t('message.yes')}`,
        },
        {
          header: 'assortment_product_published_status',
          format: 'BOOLEAN',
          exampleValue: `<code>TRUE</code> ${i18n.t('message.or')} <code>FALSE</code>`,
          description:
            `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-product-published-status-description-1')}</strong>` +
            `<br>${i18n.t('ctx.import.variants__csv-column-details__assortment-product-published-status-description-2')}` +
            `<br><code>TRUE</code> ${i18n.t('ctx.import.variants__csv-column-details__assortment-product-published-status-description-3')}` +
            `<br><code>FALSE</code> ${i18n.t('ctx.import.variants__csv-column-details__assortment-product-published-status-description-4')}`,
          mandatory: `${i18n.t('message.yes')}`,
        },
        {
          header: 'assortment_product_rpd_tax',
          format: 'DECIMAL',
          exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')}<br><code>0.1</code>`,
          description: `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-product-rpd-tax-description-1')}</strong> <br>${i18n.t('ctx.import.variants__csv-column-details__assortment-product-rpd-tax-description-2')} (${i18n.t('ctx.import.common__csv-column-details__volume-unit')}, ${i18n.t('ctx.import.common__csv-column-details__light-weight-unit')}, ${i18n.t('message.or')} ${i18n.t('ctx.import.common__csv-column-details__unit')}).`,
          mandatory: `${i18n.t('message.no')} ${i18n.t('ctx.import.variants__csv-column-details__assortment-product-rpd-tax-optional')}`,
        },
        {
          header: 'assortment_product_rpd_unit',
          format: 'VARCHAR',
          exampleValue:
            `<code>${i18n.t('ctx.import.common__csv-column-details__currency')}/${i18n.t('ctx.import.variants__csv-column-details__volume-unit')}</code> ${i18n.t('message.or')} <code>${i18n.t('ctx.import.common__csv-column-details__volume-unit')}</code>` +
            `<br><code>${i18n.t('ctx.import.common__csv-column-details__currency')}/${i18n.t('ctx.import.variants__csv-column-details__weight-unit')}</code> ${i18n.t('message.or')} <code>${i18n.t('ctx.import.common__csv-column-details__light-weight-unit')}</code>` +
            `<br><code>${i18n.t('ctx.import.common__csv-column-details__currency')}/${i18n.t('ctx.import.common__csv-column-details__unit')}</code> ${i18n.t('message.or')} <code>${i18n.t('ctx.import.common__csv-column-details__unit')}</code>`,
          description: `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-product-rpd-unit-description')}</strong>`,
          mandatory: `${i18n.t('message.no')} ${i18n.t('ctx.import.variants__csv-column-details__assortment-product-rpd-unit-optional')}`,
        },
        {
          header: 'assortment_product_tva_tax',
          format: 'DECIMAL',
          exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')}<br><code>20</code>`,
          description: `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-product-vat-tax-description')}</strong>`,
          mandatory: `${i18n.t('message.no')}`,
        },
        {
          header: 'assortment_product_stock_strategy',
          format: 'VARCHAR',
          exampleValue: `<code>manage_stock</code> ${i18n.t('message.or')} <code>force_in_stock</code>.`,
          description:
            `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-product-stock-strategy-description-1')}</strong>` +
            `<br><code>manage_stock</code> ${i18n.t('ctx.import.variants__csv-column-details__assortment-product-stock-strategy-description-2')}` +
            `<br><code>force_in_stock</code> ${i18n.t('ctx.import.variants__csv-column-details__assortment-product-stock-strategy-description-3')}`,
          mandatory: `${i18n.t('message.yes')}`,
        },
        {
          header: 'assortment_product_comments',
          format: 'VARCHAR',
          exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')}<br><code>BAYER HORIZON ARBO 1KG (=10123762)</code>`,
          description:
            `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-product-comments-description-1')}</strong> ${i18n.t('ctx.import.variants__csv-column-details__assortment-product-comments-description-2')}` +
            `<br>${i18n.t('ctx.import.variants__csv-column-details__assortment-product-comments-description-3')}`,
          mandatory: `${i18n.t('message.no')}`,
        },
        {
          header: 'assortment_partner_product_id',
          format: 'VARCHAR',
          exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')}<br><code>ABCDEF12345</code>`,
          description:
            `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-partner-product-id-description-1')}</strong>` +
            `<br>${i18n.t('ctx.import.variants__csv-column-details__assortment-partner-product-id-description-2')}`,
          mandatory: `${i18n.t('message.no')}`,
        },
        {
          header: `assortment_sellable_standalone`,
          format: 'BOOLEAN',
          exampleValue: `<code>TRUE</code>, <code>FALSE</code> ${i18n.t('ctx.import.variants__csv-column-details__assortment-sellable-standalone-example-value')}`,
          description:
            `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-sellable-standalone-to-profiles-description-1')}</strong>` +
            `<br>${i18n.t('ctx.import.variants__csv-column-details__assortment-sellable-standalone-to-profiles-description-2')}` +
            `<br><code>TRUE</code> ${i18n.t('ctx.import.variants__csv-column-details__assortment-sellable-standalone-to-profiles-description-3')}` +
            `<br><code>FALSE</code> ${i18n.t('ctx.import.variants__csv-column-details__assortment-sellable-standalone-to-profiles-description-4')}` +
            `<br><u>${i18n.t('ctx.import.variants__csv-column-details__assortment-sellable-standalone-to-profiles-description-5')}</u> ${i18n.t('ctx.import.variants__csv-column-details__assortment-sellable-standalone-to-profiles-description-6')} <code>TRUE</code>` +
            `<br><u>${i18n.t('ctx.import.variants__csv-column-details__assortment-sellable-standalone-to-profiles-description-7')}</u> ${i18n.t('ctx.import.variants__csv-column-details__assortment-sellable-standalone-to-profiles-description-8')}`,
          mandatory: `${i18n.t('message.no')}`,
        },
        {
          header: `assortment_mandatory_services`,
          format: 'VARCHAR',
          exampleValue: `${i18n.t('ctx.import.variants__csv-column-details__assortment-mandatory-services-example-value')}<br /> <code>INV0D5D3QJ9ZPVHSVC,INV7XP7AINDMAK8SVC|INV0D5D3QJ9ZPVHSVC,INV7XP7AINDMAK8SVC</code>`,
          description:
            `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-mandatory-services-description-1')}</strong>` +
            `<br>${i18n.t('ctx.import.variants__csv-column-details__assortment-mandatory-services-description-2')}`,
          mandatory: `${i18n.t('message.no')}`,
        },
        {
          header: `assortment_optional_services`,
          format: 'VARCHAR',
          exampleValue: `${i18n.t('ctx.import.variants__csv-column-details__assortment-optional-services-example-value')}<br /> <code>INV0D5D3QJ9ZPVHSVC,INV7XP7AINDMAK8SVC|INV0D5D3QJ9ZPVHSVC,INV7XP7AINDMAK8SVC</code>`,
          description:
            `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-optional-services-description-1')}</strong>` +
            `<br>${i18n.t('ctx.import.variants__csv-column-details__assortment-optional-services-description-2')}`,
          mandatory: `${i18n.t('message.no')}`,
        },
      ]

      if (window.env.VUE_APP_CONTEXT === AppContext.farmi) {
        csvColumnDetails.value.push({
          header: 'assortment_product_restricted_to_profiles',
          format: 'VARCHAR',
          exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')}<br><code>customers,sales</code>`,
          description:
            `<strong>${i18n.t('ctx.import.variants__csv-column-details__assortment-product_restricted-to-profiles-description-1')}</strong>` +
            `<br>${i18n.t('ctx.import.variants__csv-column-details__assortment-product_restricted-to-profiles-description-1')}` +
            `<br>${i18n.t('ctx.import.variants__csv-column-details__assortment-product_restricted-to-profiles-description-1')}` +
            `<br><code>customers</code> ${i18n.t('ctx.import.variants__csv-column-details__assortment-product_restricted-to-profiles-description-1')}` +
            `<br><code>sales</code> ${i18n.t('ctx.import.variants__csv-column-details__assortment-product_restricted-to-profiles-description-1')}` +
            `<br>${i18n.t('ctx.import.variants__csv-column-details__assortment-product_restricted-to-profiles-description-1')}`,
          mandatory: `${i18n.t('message.no')}`,
        })
      }
    }

    onMounted(() => {
      initializeCsvColumnDetails()
    })

    return {
      csvProperties,
      cooperativeVariantService,
      importInformation,
      csvColumnDetails,
      descriptionLines,
      pageTitle,
      uploadButtonLabel,
      reportWarning,
      title,
      nbResultText,
      exportText,
      downloadCSV,
    }
  },
})
</script>
