
import { IdfInput, IdfStandardInputs } from '@invivodf/ui-kit'
import { PropType } from 'vue'
import { IUseDiscountInput } from '../../composables/useDiscountInput'
import { useFormErrors } from '../../composables/useFormErrors'

export default {
  name: 'CDiscountReferenceInput',
  components: {
    IdfStandardInputs,
    IdfInput,
  },
  props: {
    input: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { translateError } = useFormErrors()
    return { translateError }
  },
}
