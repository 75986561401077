import { RequiredProperty } from './validator/RequiredProperty'
import { FormErrors } from '../FormErrors'

export class RequiredValidityStartDate extends RequiredProperty<Date | null> {
  constructor(validatedValue: Date | null) {
    super(validatedValue, { errorMessage: FormErrors.ValidityStartDateRequired })
  }

  validate() {
    this.errors = []
    if (this.validatedValue === null) this.errors.push(FormErrors.ValidityStartDateRequired)
    if (!(this.validatedValue instanceof Date) || Number.isNaN(+this.validatedValue))
      this.errors.push(FormErrors.ValidityStartDateInvalid)
  }
}
