
import { use18n } from '@invivodf/locales/src/use18n'
import { IdfSelect } from '@invivodf/ui-kit'
import { ref, watch } from 'vue'
import { FilterStatusOption } from '../../../domain/listing/ListingFilter'

export default {
  name: 'CSelectFilterPeriodStatus',
  components: { IdfSelect },
  setup(props, { emit }) {
    const i18n = use18n()
    const options = [
      { value: '', label: i18n.t('ctx.discount.listing.status-filter-placeholder') },
      { value: FilterStatusOption.Active, label: i18n.t('ctx.discount.status.active') },
      { value: FilterStatusOption.Upcoming, label: i18n.t('ctx.discount.status.upcoming') },
      { value: FilterStatusOption.Expired, label: i18n.t('ctx.discount.status.expired') },
    ]

    const periodStatus = ref(options[0].value)

    watch(periodStatus, () => {
      emit('input', periodStatus.value)
    })

    return { periodStatus, options }
  },
}
